import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useCreateAffiliateAgencyMutation } from "../../features/affiliateSlice";
import { Toaster, toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const AffiliateFirst = () => {
  const [popUp, setpopUp] = useState(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
      }
    };

    
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  let [createAffiliate, { isLoading }] = useCreateAffiliateAgencyMutation();

  const [affiliate, setaffiliate] = useState({
    affType: "individual",
    affName: "",
    affContact: "",
    affAddress: "",
    affRoad: "",
    affAgencyName: "",
    officePicture: "",
    businessCardPic: "",
  });

  const handlePhoneValue = (e) => {
    setaffiliate({ ...affiliate, affContact: e });
  };
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setaffiliate((prevstate) => ({
      ...prevstate,
      [name]: files[0],
    }));
    e.target = "";
  };

  const handleAffiliateChange = (e) => {
    const { name, value } = e.target;
    setaffiliate((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const handleAffiliateSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    if (affiliate.affType === "individual") {
      formdata.append("affType", affiliate.affType);
      formdata.append("affName", affiliate.affName);
      formdata.append("affContact", affiliate.affContact);
      formdata.append("affAddress", affiliate.affAddress);
      formdata.append("affRoad", affiliate.affRoad);
    } else {
      formdata.append("affType", affiliate.affType);
      formdata.append("affName", affiliate.affName);
      formdata.append("affContact", affiliate.affContact);
      formdata.append("affAddress", affiliate.affAddress);
      formdata.append("affRoad", affiliate.affRoad);
      formdata.append("affAgencyName", affiliate.affAgencyName);
      formdata.append("officePicture", affiliate.officePicture);
      formdata.append("businessCardPic", affiliate.businessCardPic);
    }
    for (var pair of formdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    createAffiliate(formdata)
      .unwrap()
      .then(() => {
        toast.success("affiliate created successfully");
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message);
      });
    setaffiliate({
      affType: "individual",
      affName: "",
      affContact: "",
      affAddress: "",
      affRoad: "",
      affAgencyName: "",
      officePicture: null,
      businessCardPic: null,
    });
  };
  return (
    <Main>
      <Search>
        <AffiliateSection>
          <SubHeading>Search</SubHeading>
            <button onClick={() => setpopUp(!popUp)}>
              <AddIcon /> Add Affiliate
            </button>
          
        </AffiliateSection>
        <SearchSection1>
          <InputSection>
            <h3>Affiliate Name:</h3>
            <input
              type="text"
              name="affName"
              id="affName"
              placeholder="Type Here ..."
            />
          </InputSection>
          <InputSection>
            <h3>Contact:</h3>
            <input
              type="text"
              name="affContact"
              id="affContact"
              placeholder="Type Here ..."
            />
          </InputSection>
          <InputSection>
            <h3>Address:</h3>
            <input
              type="text"
              name="affAddress"
              id="affAddress"
              placeholder="Type Here ..."
            />
          </InputSection>
        </SearchSection1>
        <SearchSection2>
          <InputSection>
            <h3>Road:</h3>
            <input
              type="text"
              name="affRoad"
              id="affRoad"
              placeholder="Type Here ..."
            />
          </InputSection>
          <Button>Search</Button>
        </SearchSection2>
      </Search>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <AffiliateType>
            <select
              name="affType"
              id="affType"
              value={affiliate.affType}
              onChange={handleAffiliateChange}
            >
              <option value="inidvidual">Individual</option>
              <option value="agency">agency</option>
            </select>
          </AffiliateType>
          <PopUpSection onSubmit={handleAffiliateSubmit}>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>Affiliate Name:</h3>
                    <input
                      type="text"
                      name="affName"
                      id="affName"
                      placeholder="type here..."
                      onChange={handleAffiliateChange}
                      value={affiliate.affName}
                      required
                    />
                  </InputFeild>
                  {affiliate.affType === "agency" ? (
                    <InputFeild>
                      <h3>Office Pic:</h3>
                      <span>
                        <p>upload file here</p>
                        <label htmlFor="officePicture">
                          <AttachFileIcon /> Upload
                        </label>
                      </span>
                      <input
                        type="file"
                        name="officePicture"
                        id="officePicture"
                        onChange={handleFileChange}
                      />
                    </InputFeild>
                  ) : null}
                  <PhoneInputFeild>
                    <h3>Contact:</h3>
                    <PhoneInputContainer>
                      <PhoneInput
                        inputProps={{ name: "affContact", required: true }}
                        country="pk"
                        value={affiliate.affContact}
                        onChange={handlePhoneValue}
                        placeholder="Phone Number"
                      />
                    </PhoneInputContainer>
                  </PhoneInputFeild>
                  <InputFeild>
                    <h3>Road:</h3>
                    <input
                      type="text"
                      name="affRoad"
                      id="affRoad"
                      placeholder="type here..."
                      onChange={handleAffiliateChange}
                      value={affiliate.affRoad}
                      required
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    setpopUp(false);
                    setaffiliate({
                      affType: "individual",
                      affName: "",
                      affContact: "",
                      affAddress: "",
                      affRoad: "",
                      affAgencyName: "",
                      officePicture: "",
                      businessCardPic: "",
                    });
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task>
                <InputPart>
                  {affiliate.affType === "agency" ? (
                    <>
                      <InputFeild>
                        <h3>Agency Name:</h3>
                        <input
                          type="text"
                          name="affAgencyName"
                          id="affAgencyName"
                          placeholder="type here..."
                          onChange={handleAffiliateChange}
                          value={affiliate.affAgencyName}
                          required
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Business Card:</h3>
                        <span>
                          <p>upload file here</p>
                          <label htmlFor="businessCardPic">
                            <AttachFileIcon /> Upload
                          </label>
                        </span>
                        <input
                          type="file"
                          name="businessCardPic"
                          id="businessCardPic"
                          onChange={handleFileChange}
                        />
                      </InputFeild>
                    </>
                  ) : null}
                  <InputFeild>
                    <h3>Address:</h3>
                    <textarea
                      name="affAddress"
                      id="affAddress"
                      placeholder="type here..."
                      onChange={handleAffiliateChange}
                      required
                      value={affiliate.affAddress}
                    ></textarea>
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const AffiliateSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const AffiliateType = styled.div`
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  select {
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    outline: none;
    border-radius: 5px;
    background-color: ${colors.inputWhite};
  }
`;

const PopUpSection = styled.form`
  height: calc(100% - 20%);
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  button {
    min-width: 8em;
    padding: 1em 2em;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const PhoneInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const PhoneInputContainer = styled.div`
  width: 100%;
  position: relative;
  .react-tel-input {
    .form-control {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 1px solid ${colors.inputBorderWhite};
      padding: 1em 0em 1em 3em;
      background-color: ${colors.inputWhite};
    }
    .flag-dropdown {
      border: 1px solid ${colors.inputBorderWhite};
      border-radius: 6px 0 0 6px;
    }
    .selected-flag,
    .selected-flag:hover {
      background: none;
    }
  }

  .css-i4bv87-MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
    color: ${colors.inputFeild};
    cursor: pointer;
  }
  .cross {
    position: absolute;
    top: 50%;
    right: 13%;
    transform: translateY(-50%);
    color: ${colors.hot};
  }
`;
