import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { colors } from '../../Shared/colors';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const DocumentationDataTable = () => {
  const [pageSize, setPageSize] = useState(5);

  const columns = [
    {
      field:'bookingId',
      headerName:'Booking Id',
      width:110,
    },
    {
      field:'date',
      headerName:'Date',
      width:110
    },
    {
      field: 'leadId',
      headerName: 'Lead Id',
      width: 110,
    },
    {
      field: 'project/property',
      headerName: 'Project/Property',
      width: 150,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 110,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell:(params)=>{
        return(
          params.row.status==='available'?
          <Contracted color={'#66cb66d6'}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :
          params.row.status==='E-mail'?
          <Contracted color={'#6767f4'}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :<Contracted color={'#f85a5a'}>
          <div>
            {params.row.status}
          </div>
        </Contracted>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 110,
      sortable: false ,
      filterable:false,
      hideable:false,
      renderCell:()=>{
        return(
          <Action>
            <DescriptionIcon sx={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
          </Action>
        )
      }
    },
  ];


const rows = [
];

return (
    <Main>
    <TimeSection>
        <button>
          Draft(200)
        </button>
        <button>
          Signed(23)
        </button>
        <button>
          Completed(232)
        </button>
        <button>
          Canceled(232)
        </button>
        <button>
          All(232)
        </button>
    </TimeSection>
      <LeadsSection>
        <div>

      Total Documentation:<span> 236 </span> 
        </div>
        <button>
          <PictureAsPdfIcon/>
          Export
        </button>
      </LeadsSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 3em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`
const TimeSection = styled.div`
height: 10%;
border-bottom: 1px solid ${colors.inputBorderWhite};
display: flex;
align-items: flex-end;
button{
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover{
        background-color: ${colors.brown};
        color: ${colors.white};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
width: 100%;
display: flex;
justify-content: space-between;
span{
  font-weight: bold;
}
button{
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textMed};
  padding: 1em;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.8em,0.9vw,1em);
}
`

const Table = styled.div`
height: 80%;
`




const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`


const Contracted = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
div{
width: 60%;
background-color: ${props=>props.color};
color: white;
padding: 0.5em 0.2em;
border-radius: 5px;
text-align: center;
}
`

