import React, { useEffect, useMemo,useState} from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEmailReportMutation, useGetSingleHierarchyQuery, useGetStaffQuery } from "../../features/employeeSlice";
import {Toaster,toast} from 'react-hot-toast'
import { TlwTable } from "./TlwTable";
import { useSelectedStaffContext } from "../../context/selectedStaff";
import { useLazyGetOverdueTasksByUserQuery } from "../../features/taskSlice";
import {ReportKpiTable} from './ReportKpiTable'

export const ReportsSecond = ({ staffData,name,start,end }) => {

  const {emailData} = useSelectedStaffContext()

  const loginName = localStorage.getItem('staffName')
  const role = localStorage.getItem("role");
  let staffId = localStorage.getItem('id')
  const [managerId, setmanagerId] = useState({id:'',label:''})
  const [emailShow, setemailShow] = useState(true)

  const [taskPopUp, setTaskPopUp] = useState(false);

  const [emailReport,{isLoading}] = useEmailReportMutation()
  const [trigger2] = useLazyGetOverdueTasksByUserQuery();

  useEffect(() => {
    setemailShow(true)
  }, [staffData])
  
  const handleSendEmail = () => {
    const data = emailData
    console.log("this is email data",data)
    emailReport({data}).unwrap()
    .then((res)=>{
      toast.success("Report Email Send Successfully")
      setemailShow(false)
    })
    .catch((err)=>{
      toast.error(err?.data?.error?.message)
    })

  };

  const {data:staff} = useGetSingleHierarchyQuery({staffId})

  const options = useMemo(() => {
    let options = [{id:staff?.magnager?._id,label:staff?.manager?.firstName+' '+staff?.manager?.lastName}];
    return options;
  }, [staff]);

  console.log("this is staff data",staffData)

  return (
    <>
    <Main>
      <table border={1}>
        <thead>
          <tr>
            <th></th>
            <th>Lead</th>
            <th colSpan={3}>Calls</th>
            <th colSpan={4}>Meeting</th>
            <th colSpan={2}>Average</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>TLW</th>
            <th>Whatsapp</th>
            <th>Con. Calls</th>
            <th>Non Con. Calls</th>
            <th>HO Meeting</th>
            <th>OD Meeting</th>
            <th>Site Meeting</th>
            <th>Walkin Meeting</th>
            <th>Avg Calls/Day</th>
            <th>Avg Meeting/Day</th>
          </tr>
        </thead>
        {
          staffData?
          <tbody>
          {
            staffData?.map((staff)=>(
              <ReportKpiTable name={staff.employeeName} staffData={staff} start={start} end={end}/>
            ))
          }
        </tbody>:
        <tbody>
        <tr>
          <td style={{color:`${colors.brown}`,cursor:'pointer',textDecoration:'underline'}} >-</td>
          <td style={{color:`${colors.brown}`,cursor:'pointer',textDecoration:'underline'}} >-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      </tbody>

        }
        
      </table>
      {staffData && emailShow?(
        <>
        {
          role==='ceo'||role==='v3'?
          null:
          <ButtonPart>
          <StaffInputFeild>
            <h3>Staff:</h3>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={options}
              value={managerId}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onChange={(event, newvalue) => {
                setmanagerId(newvalue)
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                } else if (
                  typeof option === "object" &&
                  option !== null &&
                  option.hasOwnProperty("label")
                ) {
                  return option.label;
                } else if (
                  typeof option === "object" &&
                  option !== null &&
                  option.hasOwnProperty("id")
                ) {
                  return option.id;
                } else {
                  return "";
                }
              }}
              style={{
                width: "100%",
                borderRadius: "5px",
                height: "2.9em",
                overflow: "hidden",
                border: `1px solid ${colors.inputBorderWhite}`,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ backgroundColor: `${colors.white}`, height: "2.9em" }}
                />
              )}
            />
          </StaffInputFeild>
          <Button onClick={handleSendEmail} disabled={isLoading}>{
                      isLoading?
                        <>Sending...</>
                        :
                        <>Send Email</>
                    }</Button>
        </ButtonPart>
}
        </>
      ) : null}
      <Toaster/>
    </Main>
    </>
  );
};


const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  background-color: ${colors.white};
  min-height: 30vh;
  table {
    width: 100%;
    min-height: 20vh;
    border-radius: 5px;
    thead {
      background-color: ${colors.brown};
      color: ${colors.white};
      th {
        padding: 8px 0px;
      }
    }
    tbody {
      td {
        padding: 8px 0px;
        text-align: center;
      }
    }
  }
`;

const ButtonPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em;
`;

const Button = styled.button`
    padding: 1em 2em;
    color: ${colors.white};
    background-color: ${colors.brown};
    border: none;
    border-radius: 5px;
    min-width: 10em;
    cursor: pointer;`

const StaffInputFeild = styled.div`
  width: 30%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;


const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const Search = styled.div`
  background-color: ${colors.white};
  width: 100%;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const KpiContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const KpiBullets = styled.div`
  width: 25%;
  height: 100%;
  height: 20em;
  padding-left: 1em;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: bold;
    span {
      color: ${colors.brown};
    }
  }
`;

const Graph = styled.div`
  width: 75%;
  height: 20em;
  background-color: ${colors.inputWhite};
  border-radius: 5px;
  padding: 1em 0;
  margin-bottom: 2em;
`;


const GraphSection = styled.div`
  background-color: ${colors.white};
  width: 90%;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
  margin-top: 3em;
  `