import { apiSlice } from "./api/apiSlice";

const InvertorySLice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        getAllInventory: builder.query({
            query:({ project, unitType, projectBlock, unitNo, status })=>({url: `inventory?status=${status}&unitNo=${unitNo}&project=${project}&projectBlock=${projectBlock}&unitType=${unitType}`}),
            transformResponse:(response,meta,arg)=>response.inventory,
            providesTags:(result,error,id)=>[{type:'Inventory'}]
        }),

        createInventory:builder.mutation({
            query:(inventory)=>({
                url:'inventory/create',
                method: 'POST',
                body:inventory
            }),
            invalidatesTags:['Inventory']
        }),

        deleteInventory:builder.mutation({
            query:(id)=>({
                url:`inventory/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:['Inventory']
        }),

        getSingleInventory:builder.query({
            query:(id)=>({
                url:`inventory/${id}`,
            }),
            transformResponse:(response,meta,arg)=>response.inventory,
            providesTags:['Inventory']
        }),
        getInventoryByProjectId:builder.query({
            query:({project,block})=>({
                url:`/inventory/getinventoryby/projectid?projectID=${project}&projectBlock=${block}`,
            }),
            transformResponse:(response,meta,arg)=>response.inventory,
            providesTags:['Inventory']
        }),
        updateInventory:builder.mutation({
            query:(id,inventory)=>({
                url:`inventory/${id}`,
                method: 'PUT',
                body:inventory
            }),
            invalidatesTags:['Inventory']
        })

    })
})

export const {useGetAllInventoryQuery,useCreateInventoryMutation,useDeleteInventoryMutation,useGetSingleInventoryQuery,useLazyGetInventoryByProjectIdQuery,useUpdateInventoryMutation} = InvertorySLice;