import React from 'react'
import styled from 'styled-components'
import { colors } from './colors'

export const ClassificationLoading = ({data}) => {
  return (
    <Main>
      <ColorCircle color={colors[data._id]}/>
      <Text>
        {data._id} {data.count}
      </Text>
    </Main>
  )
}

const Main = styled.div`
width: 100%;
display: flex;
justify-content: space-evenly;
align-items: center;
`

const ColorCircle = styled.div`
width: 1em;
height: 1em;
border-radius: 200px;
background-color: ${props=>props.color};
`

const Text = styled.div`
font-size: clamp(1.5em,2vh,2em);
width: 80%;
`