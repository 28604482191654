import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { colors } from "../../Shared/colors";
import DescriptionIcon from "@mui/icons-material/Description";
import { useGetAllPolicyQuery } from "../../features/policySlice";

export const PoliciesDataTable = () => {
  const [pageSize, setPageSize] = useState(5);

  const [desciption, setdesciption] = useState('')

  const [popUp, setpopUp] = useState(false)

  const { data: policy, isLoading } = useGetAllPolicyQuery();

  console.log(policy)

  const columns = [
    {
      field: "policyTitle",
      headerName: "Policy Title",
      width: 200,
    },
    {
      field: "effectiveFrom",
      headerName: "Effective From",
      width: 200,
      renderCell: (params) => {
        let date = params.row.effectiveFrom;
        return <div>{date.toDateString()}</div>;
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) => {
        let date = params.row.createdDate;
        return <div>{date.toDateString()}</div>;
      },
    },
    {
      field: "description",
      headerName: 'Description',
      width: 110,
      renderCell: (params) => {
        return (
          <Action onClick={() => {
            setpopUp(true)
            setdesciption(params.row.description)
          }}>
            <DescriptionIcon
              sx={{ color: `${colors.textBlue}`, cursor: "pointer" }}
            />
          </Action>
        )
      }
    }
  ];

  const rows = [];
  policy?.forEach((policy) => {
    rows.push({
      id: policy._id,
      policyTitle: policy?.policyHeading,
      effectiveFrom: new Date(policy?.effectiveFrom),
      createdDate: new Date(policy?.createdAt),
      description: policy?.policyDescription
    });
  });

  // const rows = [
  //   {
  //     id:1,
  //     policyTitle:'first policy',
  //     effectiveFrom:'13 sep,2022 7:40am',
  //     createdDate:'12 sep,2022 3:30pm'
  //   },
  //   {
  //     id:2,
  //     policyTitle:'first policy',
  //     effectiveFrom:'13 sep,2022 7:40am',
  //     createdDate:'12 sep,2022 3:30pm'
  //   },
  //   {
  //     id:3,
  //     policyTitle:'first policy',
  //     effectiveFrom:'13 sep,2022 7:40am',
  //     createdDate:'12 sep,2022 3:30pm'
  //   },
  // ];

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Policies:<span> {policy?.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isLoading}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            {desciption}
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  select {
    outline: none;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
  }
`;

const Table = styled.div`
  height: 80%;
`;
const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: auto;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.p`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
  padding: 1em;
  text-align: justify;
  line-height: 1.5em;
`;
