import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        // Production Url
        baseUrl: 'https://propware-jagiir.onrender.com/api/v1/',
        // Staging Url
        // baseUrl:'https://jagiir-propware-staging.onrender.com/api/v1/',
        // Local Url
        // baseUrl:'http://192.168.100.116:5056/api/v1/',
        // baseUrl:' http://localhost:5056/api/v1/',

        prepareHeaders: (headers) => {
            const token = `Token ${localStorage.getItem('token')}`
            if (token) {
                headers.set('authorization', `${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Inventory', 'Region', 'Project', 'Affiliate', 'Policy', 'Client', 'Employee', 'Leads', 'Task', 'Account', 'Target', 'Notification'],
    endpoints: builder => ({})
})