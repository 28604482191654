import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../../Shared/colors";
import {
  useGetSingleHierarchyQuery,
  useLazyGetStaffKpiQuery,
} from "../../../features/employeeSlice";
import { useGetAllProjectsQuery } from "../../../features/projectSlice";
import { useGetAllClientsQuery } from "../../../features/clientSlice";
import { useLazySearchLeadsFilterQuery, useLazySearchLeadsQuery } from "../../../features/leadsSlice";
import { SearchLeadsDataTable } from "./SearchLeadsDataTable";
import {toast,Toaster} from 'react-hot-toast'
import { Box } from "@mui/material";
export const SearchLeadsFirst = () => {
  let staffId = localStorage.getItem("id");
  let staffName = localStorage.getItem("staffName");
  let role = localStorage.getItem("role");
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultDate = new Date(date).toISOString().split("T")[0];

  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });

  const { data: clients } = useGetAllClientsQuery();
  const [page, setpage] = useState(0)
  const [pageSize, setpageSize] = useState(50)

  const [trigger, { data: leadData, isFetching, error }] =
    useLazySearchLeadsFilterQuery()

  const { data: project } = useGetAllProjectsQuery();

  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const clientOptions = useMemo(() => {
    let options = [];
    clients?.map((client) =>
      options.push({
        id: client?._id,
        label: client?.fullName,
      })
    );
    return options;
  }, [clients]);

  const [kpiData, setkpiData] = useState({
    start: '',
    end: defaultDate,
    clientID: clientOptions[0],
    leadID: "",
    employeeID: { id: staffId, label: staffName },
    project: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setkpiData({
      ...kpiData,
      [name]: value,
    });
  };

  useEffect(() => {
    let employeeID=undefined;
    if(role==='ceo'){
      employeeID = staffId
    }
    trigger({employeeID,page,pageSize})
  }, [page,pageSize])
  

  const handleSearch = () => {
    let start = kpiData.start;
    let end = kpiData.end;
    let projectID = kpiData.project;
    let clientID = kpiData.clientID?.id;
    let phoneNumber = kpiData.phoneNumber
    let employeeID =undefined;
    if(role==='ceo'){
      employeeID = kpiData.employeeID?.id;
    }
    let leadID= kpiData.leadID
    trigger({ start, end, projectID,clientID,employeeID,leadID,phoneNumber }).unwrap()
    .then((res)=>{
      console.log(res)
      toast.success("leads loaded successfully!")
    })
    .catch((err)=>{
      console.log(err);
      toast.error(err.data.error.message)
    })
  };

  return (
    <>
      <Main>
        <Search>
          <SubHeading>Search Leads</SubHeading>
          <SearchSection1>
            <InputSection>
              <h3>Start:</h3>
              <div>
                <input
                  type="date"
                  name="start"
                  id="start"
                  value={kpiData.start}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>End:</h3>
              <div>
                <input
                  type="date"
                  name="end"
                  id="end"
                  value={kpiData.end}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>Phone Number:</h3>

                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={kpiData.phoneNumber}
                  onChange={handleChange}
                />

            </InputSection>
            <InputSection>
              <h3>Project:</h3>
              <div>
                <select
                  name="project"
                  id="project"
                  value={kpiData.project}
                  onChange={handleChange}
                >
                  <option value="">select</option>
                  {project?.map((proj) => (
                    <option value={proj._id}>{proj.projectName}</option>
                  ))}
                </select>
              </div>
            </InputSection>
          </SearchSection1>
          <SearchSection2>
            <InputSection>
              <h3>Lead ID:</h3>
              <div>
                <input
                  type="text"
                  name="leadID"
                  id="lead"
                  value={kpiData.leadID}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <StaffInputFeild>
              <h3>Client:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={clientOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setkpiData({ ...kpiData, clientID: value });
                }}
                value={kpiData.clientID}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
                renderOption={(props,option)=>(
                  <Box
                  component="li"
                  {...props}
                  key={option.id}

                  >
                    {option.label}
                  </Box>
                )}
              />
            </StaffInputFeild>
            {
              role==='ceo'?
              <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={staffOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setkpiData({ ...kpiData, employeeID: value });
                }}
                value={kpiData.staff}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>:null
            }

            <Button onClick={handleSearch} disabled={isFetching}>
              {isFetching ? <>Searching...</> : <>Search</>}
            </Button>
          </SearchSection2>
        </Search>
      </Main>
      <Toaster/>
      <SearchLeadsDataTable leads={leadData} page={page} pageSize={pageSize} setPageSize={setpageSize} setPage={setpage} isFetching={isFetching}/>
    </>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  width: 100%;
  max-width: 25em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 95%;
    padding-right: 1em;
    background-color: ${colors.white};

    select,
    input[type="date"],
    input[type="text"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 90%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  min-width: 10em;
  cursor: pointer;
`;

const StaffInputFeild = styled.div`
  width: 20em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;
