import { apiSlice } from "./api/apiSlice";


const clientSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        searchClient:builder.query({
            query:(phone)=>({
                url:`client/search?phoneNo=${phone}`
            }),
            transformResponse:(response)=>response.clients,
        }),
        addClient:builder.mutation({
            query:(lead)=>({
                url:'client/create',
                method:'POST',
                body:lead
            }),
            invalidatesTags:["Client"]
        }),
        getAllClients:builder.query({
            query:()=>({
                url:'client'
            }),
            transformResponse:(response,meta,arg)=>response.LoginEmployeeClients,
            providesTags:['Client']
            
        }),
        editClient:builder.mutation({
            query:({id,body})=>({
                url:`client/edit_client_attribute/${id}`,
                method:'PUT',
                body
            }),
            invalidatesTags:['Client']
        }),
        deleteClient:builder.mutation({
            query:(clientId)=>({
                url:`/client/delete/${clientId}`,
                method:'DELETE',
            }),
            invalidatesTags:['Client']
        })
    })
})


export const {useSearchClientQuery,useAddClientMutation,useGetAllClientsQuery,useEditClientMutation,useDeleteClientMutation} = clientSlice;