import React, { useState, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import {
    useAssignSelectedClientsMutation,
  useGetStaffQuery,
  useGetTeamClientsQuery,
} from "../../../features/employeeSlice";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../../Shared/colors";
import {toast , Toaster} from 'react-hot-toast'

const columns = [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.fullName}>
          <div>{params.row.fullName}</div>
        </Tooltip>
      );
    },
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 150,
    renderCell: (params) => {
      return <div>{params.row.gender}</div>;
    },
  },
  // {
  //   field: "currentTaskType",
  //   headerName: "Task Type",
  //   width: 150,
  // },
  // {
  //   field: "currentSubTypeTask",
  //   headerName: "Task Sub Type",
  //   width: 150,
  // },
  {
    field: "clientType",
    headerName: "Client Type",
    width: 150,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.email}>
          <div>{params.row.email}</div>
        </Tooltip>
      );
    },
  },
];

export const TeamTable = ({ teamId }) => {
  const { data: teamData, isFetching,error} = useGetTeamClientsQuery({ teamId });
  const { data: staff } = useGetStaffQuery();
    const [assignClients]=useAssignSelectedClientsMutation()

  const [pageSize, setPageSize] = useState(5);
  const [selectedRows, setSelectedRows] = useState([])

console.log("this is team data",teamData,error)

  console.log("these are selected rows",selectedRows);
  console.log("staff in team", staff);

  const options = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );
    return options;
  }, [staff]);

  const [team, setteam] = useState(options[0]);

  let rows = useMemo(() => {
    let rows = [];

    teamData?.forEach((team) => {
      rows.push({
        id: team._id,
        fullName: team.fullName,
        gender: team.gender,
        email: team.email,
        clientType: team.clientType,
        phone:team?.cellNo[0]?.phoneNo
      });
    });
    return rows;
  }, [teamData]);

  const handleAssign = () =>{
    let data = {
        clientIds:selectedRows
    }
    let id = team.id
    assignClients({data,id}).unwrap()
    .then((res)=>{
        console.log(res)
        toast.success("Clients Assigned Successfully")
        setteam('')
        setSelectedRows([])
    })
    .catch((error)=>{
        toast.error(error?.data?.error?.message)
        console.log(error)
        setteam('')
        setSelectedRows([])
    })
  }

  return (
    <Main>
      <DropDown>
        <AutocompleteSection>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            value={team}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            onChange={(event, newvalue) => {
              setteam(newvalue);
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              } else if (
                typeof option === "object" &&
                option !== null &&
                option.hasOwnProperty("label")
              ) {
                return option.label;
              } else if (
                typeof option === "object" &&
                option !== null &&
                option.hasOwnProperty("id")
              ) {
                return option.id;
              } else {
                return "";
              }
            }}
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "2.9em",
              overflow: "hidden",
              border: `1px solid ${colors.inputBorderWhite}`,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ backgroundColor: `${colors.white}`, height: "2.9em" }}
              />
            )}
          />
        </AutocompleteSection>
        <Button onClick={handleAssign}>Assign</Button>
      </DropDown>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
          onSelectionModelChange={(newSelectionModel) =>
            setSelectedRows(newSelectionModel)
          }
          selectionModel={selectedRows}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={isFetching}
        sx={{
          border: "none",
        }}
      />
      <Toaster/>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

const DropDown = styled.div`
display: flex;
justify-content: space-between;
padding: 1em;
`;

const AutocompleteSection = styled.div`
width: 20em;
`

const Button = styled.button`
min-width: 8em;
background-color: ${colors.brown};
color: ${colors.white};
border-radius: 5px;
border: none;
cursor: pointer;
`