import React from 'react'
import styled from 'styled-components'
import { CalendarFirst } from '../../Components/Leads/Calendar/CalendarFirst'



export const CalenderComponent = () => {
  return (
    <Main>
        <CalendarFirst/>
    </Main>
  )
}


const Main = styled.div``