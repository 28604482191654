import React, { useState,useEffect } from 'react'
import styled from 'styled-components'
import {colors} from '../../../Shared/colors'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useGetCallReportsQuery, useGetDashboardReportQuery } from '../../../features/employeeSlice';

// const data = [
//     {
//       name: 'Total',
//       uv: 400,
//     },
//     {
//       name: 'Verified',
//       uv: 100,
//     },
//     {
//       name: 'Unique',
//       uv: 300,
//     }
//   ];

export const CallsReport = () => {

  // const {data:callReport} = useGetCallReportsQuery()

  const {data:dashboard} = useGetDashboardReportQuery()

  const [callReport, setcallReport] = useState([])

  console.log(dashboard);

  useEffect(() => {
    if (!dashboard) {
      return;
    }

    let sortedcalls = dashboard?.callsReport
      ?.slice()
      .sort((a, b) => a._id - b._id);

    if (!sortedcalls) {
      return;
    }

    let callReport = [];

    sortedcalls.forEach((call, index) => {
      callReport.push({
        name: `${index + 1} week`,
        ...call,
      });
    });

    setcallReport(callReport);
  }, [dashboard]);


//   let data = []
// callReport?.forEach(call => {
//   if(call._id===1){
//     data.push({name:'Jan',calls:call.calls})
//   }
//   else if(call._id===2){
//     data.push({name:'Feb',calls:call.calls})
//   }
//   else if(call._id===3){
//     data.push({name:'March',calls:call.calls})
//   }
//   else if(call._id===4){
//     data.push({name:'April',calls:call.calls})
//   }
//   else if(call._id===5){
//     data.push({name:'May',calls:call.calls})
//   }
//   else if(call._id===6){
//     data.push({name:'June',calls:call.calls})
//   }
//   else if(call._id===7){
//     data.push({name:'July',calls:call.calls})
//   }
//   else if(call._id===8){
//     data.push({name:'Aug',calls:call.calls})
//   }
//   else if(call._id===9){
//     data.push({name:'Sep',calls:call.calls})
//   }
//   else if(call._id===10){
//     data.push({name:'Oct',calls:call.calls})
//   }
//   else if(call._id===11){
//     data.push({name:'Nov',calls:call.calls})
//   }
//   else if(call._id===12){
//     data.push({name:'Dec',calls:call.calls})
//   }
//   else{
//     return
//   }
// });

  return (
    <Main>
      <p>Calls Report</p>
    <Graph>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={callReport}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="calls" stroke="#E3492D" fill="#e3492d0d" />
        </AreaChart>
      </ResponsiveContainer>
      </Graph>
      </Main>
  )
}


const Main = styled.div`
width: 40%;
min-width: 30em;
height: 20em;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
background-color: ${colors.inputWhite};
margin-top: 3em;
padding:1em;
p{
  font-size: clamp(1em,3vh,4em);
  margin-bottom: 1em;
}

`
const Graph = styled.div`
width: 100%;
height: 80%;
`
