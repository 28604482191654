import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useEmailReportMutation,
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../features/employeeSlice";
import { Toaster, toast } from "react-hot-toast";
import { TlwTable } from "./TlwTable";
import { useSelectedStaffContext } from "../../context/selectedStaff";
import { useLazyGetOverdueTasksByUserQuery } from "../../features/taskSlice";
import { KpiTaskTable } from "./kpiTaskTable";

export const ReportKpiTable = ({ name, start, end, staffData }) => {
  console.log("asdfsadfasfasfafasff");
  const callData = [
    {
      name: "SMS",
      count: 0,
    },
    {
      name: "Call Attempt",
      count: 0,
    },
    {
      name: "Call connected",
      count: 0,
    },
    {
      name: "Call Not Connected",
      count: 0,
    },
    {
      name: "Whatsapp",
      count: 0,
    },
    {
      name: "Followed Up",
      count: 0,
    },
  ];
  const meetingData = [
    {
      name: "Video Meeting",
      count: 0,
    },
    {
      name: "Outdoor",
      count: 0,
    },
    {
      name: "Site",
      count: 0,
    },
    {
      name: "Walk In",
      count: 0,
    },
    {
      name: "Head Office Meeting",
      count: 0,
    },
  ];
  const salesData = [
    {
      name: "Token Payment",
      count: 0,
    },
    {
      name: "Complete Down Payment",
      count: 0,
    },
    {
      name: "Closed (Lost)",
      count: 0,
    },
    {
      name: "Closed (Won)",
      count: 0,
    },
  ];

  const data = [
    {
      name: "Overdue",
      uv: staffData?.overdueTasks,
    },
    {
      name: "Today",
      uv: staffData?.pendingTasks,
    },
    {
      name: "Tomorrow",
      uv: staffData?.tomorrowTasks,
    },
  ];

  staffData?.kpiReport?.forEach((staffData) => {
    if (staffData._id === "Call") {
      staffData?.kpi?.forEach((kpi) => {
        if (kpi.subTask === "SMS") {
          callData[0].count = kpi.count;
        } else if (kpi.subTask === "Call Attempt") {
          callData[1].count = kpi.count;
        } else if (kpi.subTask === "Call connected") {
          callData[2].count = kpi.count;
        } else if (kpi.subTask === "Call Not Connected") {
          callData[3].count = kpi.count;
        } else if (kpi.subTask === "Whatsapp") {
          callData[4].count = kpi.count;
        } else if (kpi.subTask === "Followed Up") {
          callData[5].count = kpi.count;
        }
      });
    } else if (staffData._id === "Meeting") {
      staffData?.kpi?.forEach((kpi) => {
        if (kpi.subTask === "Video Meeting") {
          meetingData[0].count = kpi.count;
        } else if (kpi.subTask === "Outdoor") {
          meetingData[1].count = kpi.count;
        } else if (kpi.subTask === "Site") {
          meetingData[2].count = kpi.count;
        } else if (kpi.subTask === "Walk In") {
          meetingData[3].count = kpi.count;
        } else if (kpi.subTask === "Head Office Meeting") {
          meetingData[4].count = kpi.count;
        }
      });
    } else if (staffData._id === "Sales") {
      staffData?.kpi?.forEach((kpi) => {
        if (kpi.subTask === "Token Payment") {
          salesData[0].count = kpi.count;
        } else if (kpi.subTask === "Complete Down Payment") {
          salesData[1].count = kpi.count;
        } else if (kpi.subTask === "Closed (Lost)") {
          salesData[2].count = kpi.count;
        } else if (kpi.subTask === "CLosed (Won)") {
          salesData[3].count = kpi.count;
        }
      });
    }
  });

  const loginName = localStorage.getItem("staffName");
  const role = localStorage.getItem("role");
  let staffId = localStorage.getItem("id");
  const [managerId, setmanagerId] = useState({ id: "", label: "" });
  const [emailShow, setemailShow] = useState(true);

  const [tlwPopUp, settlwPopUp] = useState(false);
  const [taskPopUp, settaskPopUp] = useState(false);
  const [kpiTaskData, setkpiTaskData] = useState(null)

  const handleKpiSelect = (type,subtype) => {
    let typeData = staffData?.kpiReport?.find((kpi)=>type===kpi?._id)
    if(!typeData){
      return
    }
    let subTypeData = typeData?.kpi?.find((kpi)=>subtype===kpi?.subTask)
    if(!subTypeData){
      return
    }
    setkpiTaskData(subTypeData?.tasks)
    settaskPopUp(true)
  };
  const [emailReport, { isLoading }] = useEmailReportMutation();
  const [trigger2] = useLazyGetOverdueTasksByUserQuery();

  const {
    selectedStaff,
    updateSelectedStaff,
    updateOverdueTask,
    updateEmailData,
  } = useSelectedStaffContext();

  const CallCountSum = callData.reduce((sum, item) => sum + item.count, 0);

  const averageCalls = useMemo(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const totalCalls = CallCountSum;

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const totalDays =
      Math.floor((endDate - startDate) / millisecondsPerDay) + 1;
    let nonSundayCount = 0;

    for (let i = 0; i < totalDays; i++) {
      const currentDate = new Date(
        startDate.getTime() + i * millisecondsPerDay
      );
      if (currentDate.getDay() !== 0) {
        nonSundayCount++;
      }
    }
    const averageCalls = totalCalls / nonSundayCount;
    const roundedAverage = Math.round(averageCalls);
    return roundedAverage;
  }, [start, end, CallCountSum]);

  const handleSelectedStaff = () => {
    updateSelectedStaff(staffData);
  };


  useEffect(() => {
    let staff = selectedStaff.employeeID;
    trigger2({ staff })
      .unwrap()
      .then((res) => {
        updateOverdueTask(res);
        updateEmailData({
          name: name,
          tlw: staffData?.leadWorkingCount,
          call: CallCountSum,
          conCall: callData[2]?.count,
          nonConCall: callData[3]?.count,
          avgCall: averageCalls,
          hOM: meetingData[4]?.count,
          oDM: meetingData[1]?.count,
          sM: meetingData[2]?.count,
          wIM: meetingData[3]?.count,
          avgMeeting: staffData?.avgWalkInMeetingsPerDay,
          start: start,
          end: end,
          sendTo: managerId.id,
        });
      });
  }, [selectedStaff]);

  useEffect(() => {
    setemailShow(true);
  }, [staffData]);

  return (
    <>
      <tr>
        <td
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={handleSelectedStaff}
        >
          {name ? name : loginName}
        </td>
        <td
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => (staffData ? settlwPopUp(true) : null)}
        >
          {staffData?.totalLeadWorking?.TLW || <>-</>}
        </td>
        <td
          onClick={() => handleKpiSelect("Call","Whatsapp")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {callData[4]?.count}
        </td>
        <td
          onClick={() => handleKpiSelect("Call","Call connected")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {callData[2]?.count}
        </td>
        <td
          onClick={() => handleKpiSelect("Call","Call Not Connected")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {callData[3]?.count}
        </td>
        <td
          onClick={() => handleKpiSelect("Meeting","Head Office Meeting")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {meetingData[4]?.count || <>-</>}
        </td>
        <td
          onClick={() => handleKpiSelect("Meeting","Outdoor")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {meetingData[1]?.count || <>-</>}
        </td>
        <td
          onClick={() => handleKpiSelect("Meeting","Site")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {meetingData[2]?.count || <>-</>}
        </td>
        <td
          onClick={() => handleKpiSelect("Meeting","Walk In")}
          style={{
            color: `${colors.brown}`,
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {meetingData[3]?.count || <>-</>}
        </td>
        <td>{Math.floor(staffData?.avgCallPerDay) || <>-</>}</td>
        <td>{Math.floor(staffData?.avgMeetingPerDay) || <>-</>}</td>
      </tr>
      <PopUpContainer popUp={tlwPopUp}>
        <PopUp>
          <Cross
            onClick={() => settlwPopUp(!tlwPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TlwTable tasks={staffData?.totalLeadWorking?.TLWT} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => settaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <KpiTaskTable data={kpiTaskData}/>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  background-color: ${colors.white};
  min-height: 30vh;
  table {
    width: 100%;
    min-height: 20vh;
    border-radius: 5px;
    thead {
      background-color: ${colors.brown};
      color: ${colors.white};
      th {
        padding: 8px 0px;
      }
    }
    tbody {
      td {
        padding: 8px 0px;
        text-align: center;
      }
    }
  }
`;

const ButtonPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em;
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  min-width: 10em;
  cursor: pointer;
`;

const StaffInputFeild = styled.div`
  width: 30%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const Search = styled.div`
  background-color: ${colors.white};
  width: 100%;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const KpiContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const KpiBullets = styled.div`
  width: 25%;
  height: 100%;
  height: 20em;
  padding-left: 1em;
  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: bold;
    span {
      color: ${colors.brown};
    }
  }
`;

const Graph = styled.div`
  width: 75%;
  height: 20em;
  background-color: ${colors.inputWhite};
  border-radius: 5px;
  padding: 1em 0;
  margin-bottom: 2em;
`;

const GraphSection = styled.div`
  background-color: ${colors.white};
  width: 90%;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
  margin-top: 3em;
`;
