import { apiSlice } from "./api/apiSlice";

const accountSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        getDownPayment:builder.query({
            query:(body)=>({
                url:`/downPayment/status?status=${body}`
            }),
            transformResponse:(response)=>response.downPaymentStatus,
            provideTags:['Account']
        }),
        getTokenPayment:builder.query({
            query:(body)=>({
                url:`/tokenPayment/status?status=${body}`
            }),
            transformResponse:(response)=>response.tokenPaymentStatus,
            provideTags:['Account']
        }),
        getTokens:builder.query({
            query:()=>({
                url:`/tokenPayment`
            }),
            transformResponse:(response)=>response.token,
            provideTags:['Account']
        }),
    })
})


export const {useGetDownPaymentQuery,useGetTokenPaymentQuery,useGetTokensQuery}=accountSlice;