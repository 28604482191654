import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../Shared/colors'
import {jsPDF} from 'jspdf';
import { useGetDashboardReportQuery } from '../../../features/employeeSlice';

const DownloadPdf = () => {

    const { data: dashboard } = useGetDashboardReportQuery()


    const generatePDF = (data) => {
        if(!data){
            return
        }
        const doc = new jsPDF();
        
        doc.text("Lead Report", 10, 10);
        data.leadReport.forEach((item, index) => {
          doc.text(`month: ${item._id}, Leads: ${item.leads}`, 10, 20 + index * 10);
        });
      
        doc.text("Call Report",10, 10);
        data?.callReport?.forEach((item, index) => {
          
        })
        doc.text("Classification Report", 10, 50);
        data.classificationReport.forEach((item, index) => {
          doc.text(`month: ${item._id}, Count: ${item.count}`, 10, 60 + index * 10);
        });
      
        doc.text("Task Report", 10, 90);
        doc.text(`One Task: ${data.taskReport.oneTask}`, 10, 100);
        doc.text(`Two Tasks: ${data.taskReport.twoTasks}`, 10, 110);
        doc.text(`More Than Two Tasks: ${data.taskReport.moreThanTwoTasks}`, 10, 120);
      
        doc.save("report.pdf");
      };

  return (
    <Main>
        <button onClick={()=>generatePDF(dashboard)}>
            Download Report
        </button>
    </Main>
  )
}

export default DownloadPdf

const Main = styled.div`
width: 100%;
margin-top: 3em;
display: flex;
justify-content: center;
align-items: center;
button{
    color: ${colors.white};
    background-color: ${colors.brown};
    border: none;
    padding: 1em 2em;
    font-size: 1.3em;
    border-radius: 10px;
    cursor: pointer;
}
`