export const Banks=[
    'Allied Bank Limited',
    'Albaraka Bank (Pakistan) Limited',
    'Askari Bank Limited',
    'Bank Alfalah Limited',
    'Bank Al Habib Limited',
    'BankIslami Pakistan Limited',
    'Burj Bank Limited',
    'Dubai Islamic Bank Pakistan Limited',
    'Faysal Bank Limited',
    'First Women Bank Limited',
    'Habib Bank Limited',
    'Habib Metropolitan Bank Limited',
    'JS Bank Limited',
    'MCB Bank Limited',
    'Meezan Bank Limited',
    'National Bank of Pakistan',
    'NRSP Microfinance Bank Limited',
    'Samba Bank Limited',
    'Silkbank Limited',
    'Sindh Bank Limited',
    'Standard Chartered Bank (Pakistan) Limited',
    'Summit Bank Limited',
    'The Bank of Khyber',
    'The Bank of Punjab',
    'The Punjab Provincial Cooperative Bank Limited',
    'United Bank Limited'
  ]