import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../../Shared/colors";
import { useGetStaffQuery } from "../../../features/employeeSlice";

export const MalkiyetContactFirst = () => {
  const [pageSize, setPageSize] = useState(10);

  const { data: staff, isFetching } = useGetStaffQuery();

  console.log("this is staff", staff);

  const columns = [
    {
        field: 'empId',
        headerName: 'EMP. ID',
        width: 150,
    },
    {
      field: "empName",
      headerName: "EMP. NAME",
      width: 150,
    },
    // {
    //   field: "cnic",
    //   headerName: "Cnic",
    //   width: 150,
    // },
    {
      field: "desig",
      headerName: "DESIG",
      width: 150,
    },
    {
      field: "city",
      headerName: "CITY",
      width: 110,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    // {
    //   field: 'action',
    //   headerName: 'Actions',
    //   width: 80,
    //   sortable: false ,
    //   filterable:false,
    //   hideable:false,
    //   renderCell:()=>{
    //     return(
    //       <Action>
    //         <EditIcon style={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
    //       </Action>
    //     )
    //   }
    // },
  ];

  const rows = [];
  staff?.forEach((staff) => {
    rows.push({
      id: staff._id,
      cnic: staff.cnic,
      empId: staff.empID,
      empName: staff.firstName + " " + staff.lastName,
      email: staff.email,
      phone: staff.phoneNum,
      desig: staff.designation,
      city: staff.address,
    });
  });

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Staff:<span> {staff?.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isFetching}
          sx={{
            border: "none",
          }}
        />
      </Table>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;
