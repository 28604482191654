import { apiSlice } from "./api/apiSlice";

const taskSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        addClientLeadTask:builder.mutation({
            query:(task)=>({
                url:'task/create',
                method:'POST',
                body:task
            }),
            invalidatesTags:['Task','Client','Leads']
        }),
        addNewTaskOnly:builder.mutation({
            query:(task)=>({
                url:'task/new/create',
                method:'POST',
                body:task
            }),
            invalidatesTags:['Task']
        }),
        addNewTask:builder.mutation({
            query:({formdata,id})=>({
                url:`task/${id}`,
                method:'PUT',
                body:formdata
            }),
            invalidatesTags:['Task']
        }),
        addNewTaskLeadId:builder.mutation({
            query:({formdata,id})=>({
                url:`lead/add-task/${id}`,
                method:'PUT',
                body:formdata
            }),
            invalidatesTags:['Task']
        }),
        getCompletedTaskEmpId:builder.query({
            query:({empId,leadId})=>({
                url:`/task/completed/employee/${empId}/lead/${leadId}`
            }),
            transformResponse:(res)=>res.completedTask
        }),
        getCompletedTask:builder.query({
            query:(id)=>({
                url:`/task/completed/${id}`
            }),
            transformResponse:(res)=>res.completedTask
        }),
        getCompletedTaskByLeadId:builder.query({
            query:(id)=>({
                url:`/task/completed/${id}`
            }),
            transformResponse:(res)=>res.completedTask
        }),
        getHierarchyCompletedTask:builder.query({
            query:(id)=>({
                url:`/task/hierarchical-tasks/${id}`
            }),
            transformResponse:(res)=>res.completedTask
        }),
        getCompletedTaskByTaskId:builder.query({
            query:(id)=>({
                url:`/task/todotask/completed/${id}`
            }),
            transformResponse:(res)=>res.completedTask
        }),
        getOverdueTasksByUser:builder.query({
            query:({staff})=>({
                url:`/kpi/pending-tasks?user=${staff}`
            }),
            transformResponse:(res)=>res.tasks
        })
    })
})

export const {useAddClientLeadTaskMutation,useAddNewTaskOnlyMutation,useAddNewTaskMutation,useAddNewTaskLeadIdMutation,useGetCompletedTaskQuery,useLazyGetCompletedTaskByLeadIdQuery,useGetCompletedTaskEmpIdQuery,useGetCompletedTaskByTaskIdQuery,useGetHierarchyCompletedTaskQuery,useLazyGetOverdueTasksByUserQuery} = taskSlice;