import React, { useMemo ,useState} from "react";
import styled from "styled-components";
import { useGetHierarchyQuery } from "../../../features/employeeSlice";
import { colors } from "../../../Shared/colors";
import { HierarchyDatatable } from "./HierarchyDatatable";
import TeamTreeGraph from "./TeamTreeGraph";

const TeamHierarchySecond = () => {

  const [tableData, settableData] = useState(null)

  const setTableData = (data) =>{
    settableData(data)
  }

  console.log("this is table data",tableData)
  function convertToTreeData(apiResponse) {
    const employeesMap = new Map();

    // Step 1: Create all employee nodes in the employeesMap
    apiResponse?.forEach((node) => {
      const { _id, employee, manager, subordinates } = node;

      if (!employeesMap.has(_id)) {
        employeesMap.set(_id, {
          name:
            employee.firstName +
            " " +
            employee.lastName +
            " " +
            " ( " +
            employee.role +
            " ) ",
          id: employee._id,
          children: [],
        });
      }

      const employeeNode = employeesMap.get(_id);

      // Step 2: Link employees to their managers using the manager's ID
      if (manager) {
        const managerId = manager._id;
        if (!employeesMap.has(managerId)) {
          employeesMap.set(managerId, {
            name:
              manager.firstName +
              " " +
              manager.lastName +
              " " +
              " ( " +
              manager.role +
              " ) ",
            id: manager._id,
            children: [],
          });
        }
        employeesMap.get(managerId).children.push(employeeNode);
      }

      // Step 3: Add the employee's subordinates to their children
      if (subordinates && subordinates.length > 0) {
        subordinates.forEach((subordinate) => {
          const subordinateId = subordinate._id;
          if (!employeesMap.has(subordinateId)) {
            employeesMap.set(subordinateId, {
              name:
                subordinate.firstName +
                " " +
                subordinate.lastName +
                " " +
                " ( " +
                subordinate.role +
                " ) ",
              id: subordinate._id,
              children: [],
            });
          }
          employeeNode.children.push(employeesMap.get(subordinateId));
        });
      }
    });

    console.log("this is employee map", employeesMap);
    // Find the CEO and return the top-level node
    const ceoNode = apiResponse?.find(
      (node) => node.employee.role.toLowerCase() === "ceo"
    );
    const treeData = {
      name: ceoNode
        ? ceoNode.employee.firstName + " " + ceoNode.employee.lastName
        : "CEO",
      children: ceoNode ? [employeesMap.get(ceoNode._id)] : [],
    };

    return treeData.children[0];
  }
  const { data: hierarchy } = useGetHierarchyQuery();
  console.log("this is heirarchy", hierarchy);

  const apiTreeData = useMemo(() => convertToTreeData(hierarchy), [hierarchy]);
  console.log("this is new heirarchy", apiTreeData);
  const treeData = apiTreeData
    ? apiTreeData
    : {
        name: "Ceo",
        children: [],
      };
  return (
    <>
    <Main>
      <TeamTreeGraph data={treeData} setTableData={setTableData}/>
    </Main>
    <HierarchyDatatable tableData={tableData}/>
    </>
  );
};

export default TeamHierarchySecond;

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 60vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
