import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useRegisterUserMutation } from "../../features/authSlice";
import { Toaster, toast } from "react-hot-toast";
import { useGetAllRegionQuery } from "../../features/regionSlice";

export const StaffFirst = () => {
  const [popUp, setpopUp] = useState(false);

  const role = localStorage.getItem("role");

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const [staff, setstaff] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNum: null,
    cnic: null,
    address: "",
    gender: "",
    designation: "",
    profilePic: null,
    region: "",
    assignArea: "",
    role: "",
    empID:''
  });

  let { data: region } = useGetAllRegionQuery();

  let [registerUser, { isLoading }] = useRegisterUserMutation();

  const handleStaffChange = (e) => {
    const { name, value } = e.target;
    setstaff((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setstaff((prevstate) => ({
      ...prevstate,
      profilePic: e.target.files[0],
    }));
  };
  const handleRegisterStaff = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("firstName", staff.firstName);
    formdata.append("lastName", staff.lastName);
    formdata.append("email", staff.email);
    formdata.append("password", staff.password);
    formdata.append("phoneNum", staff.phoneNum);
    formdata.append("cnic", staff.cnic);
    formdata.append("address", staff.address);
    formdata.append("gender", staff.gender);
    formdata.append("designation", staff.designation);
    formdata.append("profilePic", staff.profilePic);
    formdata.append("region", staff.region);
    formdata.append("assignArea", staff.assignArea);
    formdata.append("role", staff.role);
    formdata.append('empID',staff.empID)

    registerUser(formdata)
      .unwrap()
      .then(() => {
        toast.success("Staff added successfully");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      });
    setstaff({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNum: null,
      cnic: null,
      address: "",
      gender: "",
      designation: "",
      profilePic: null,
      region: "",
      assignArea: "",
      role: "",
      empID:''
    });
  };
  return (
    <Main>
      <Search>
        <AffiliateSection>
          <SubHeading>Search</SubHeading>
          {role === "hr" || role === "v3" || role === "developer" || role==='director'||role==='ceo' ? (
            <button onClick={() => setpopUp(!popUp)}>
              <AddIcon /> Add Staff
            </button>
          ) : null}
        </AffiliateSection>
        <SearchSection1>
          <InputSection>
            <h3>Search:</h3>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Employee Id"
            />
          </InputSection>
          <InputSection>
            <h3>Designation:</h3>
            <div>
              <select name="designation" id="designation">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Team Type:</h3>
            <div>
              <select name="teamType" id="teamType">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
        </SearchSection1>
        <SearchSection2>
          <InputSection>
            <h3>Staff:</h3>
            <div>
              <select name="staff" id="staff">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Region:</h3>
            <div>
              <select name="region" id="region">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <Button>Search</Button>
        </SearchSection2>
      </Search>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection action="" onSubmit={handleRegisterStaff}>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>First Name:</h3>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={staff.firstName}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Last Name:</h3>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={staff.lastName}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Email:</h3>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={staff.email}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Password:</h3>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={staff.password}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Phone Num:</h3>
                    <input
                      type="tel"
                      name="phoneNum"
                      id="phoneNum"
                      value={staff.phoneNum}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Cnic:</h3>
                    <input
                      type="tel"
                      name="cnic"
                      id="cnic"
                      value={staff.cnic}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Address:</h3>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={staff.address}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    setpopUp(false);
                    setstaff({
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                      phoneNum: null,
                      cnic: null,
                      address: "",
                      gender: "",
                      designation: "",
                      profilePic: null,
                      region: "",
                      assignArea: "",
                      role: "",
                    });
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>EmpId:</h3>
                    <input type="text" name="empID" value={staff.empID} onChange={handleStaffChange} />
                  </InputFeild>
                  <InputFeild>
                    <h3>Gender:</h3>
                    <div>
                      <select
                        name="gender"
                        id="gender"
                        value={staff.gender}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Designation:</h3>
                    <div>
                      <select
                        name="designation"
                        id="designation"
                        value={staff.designation}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="dos">Dos</option>
                        <option value="doa">Doa</option>
                        <option value="account">Account</option>
                        <option value="admin">Admin</option>
                        <option value="dm">Dm</option>
                        <option value="rsm">Rsm</option>
                        <option value="sm">Sm</option>
                        <option value="manger">Manager</option>
                        <option value="abdm">Abdm</option>
                        <option value="bdm">Bdm</option>
                        <option value="tl">Tl</option>
                        <option value="bda">Bda</option>
                        <option value="am">Am</option>
                        <option value="dcc">Dcc</option>
                        <option value="Tse">Tse</option>
                        <option value="developer">Developer</option>
                        <option value="v3">V3</option>
                        <option value="Hr">hr</option>
                        <option value="aps">Aps</option>
                        <option value="tse">Tse</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Region:</h3>
                    <div>
                      <select
                        name="region"
                        id="region"
                        value={staff.region}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        {region?.map((region) => (
                          <option key={region._id} value={region._id}>
                            {region.regionName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Assign Area:</h3>
                    <input
                      type="text"
                      name="assignArea"
                      id="assignArea"
                      value={staff.assignArea}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Role:</h3>
                    <div>
                      <select
                        name="role"
                        id="role"
                        value={staff.role}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="dos">Dos</option>
                        <option value="doa">Doa</option>
                        <option value="account">Account</option>
                        <option value="admin">Admin</option>
                        <option value="dm">Dm</option>
                        <option value="rsm">Rsm</option>
                        <option value="sm">Sm</option>
                        <option value="manger">Manager</option>
                        <option value="abdm">Abdm</option>
                        <option value="bdm">Bdm</option>
                        <option value="tl">Tl</option>
                        <option value="bda">Bda</option>
                        <option value="am">Am</option>
                        <option value="dcc">Dcc</option>
                        <option value="Tse">Tse</option>
                        <option value="developer">Developer</option>
                        <option value="v3">V3</option>
                        <option value="Hr">hr</option>
                        <option value="aps">Aps</option>
                        <option value="tse">Tse</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Attachment:</h3>
                    <span>
                      <p>upload file here</p>
                      <label htmlFor="profilePic">
                        <AttachFileIcon /> Upload
                      </label>
                      {staff.profilePic && (
                        <UploadedFile
                          src={URL.createObjectURL(staff.profilePic)}
                          alt="uploaded file"
                        />
                      )}
                    </span>
                    <input
                      type="file"
                      name="profilePic"
                      id="profilePic"
                      onChange={handleFileChange}
                      required
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleRegisterStaff}
                  disabled={isLoading}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const AffiliateSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  overflow: scroll;
`;

const Cross = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.form`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 50%;
  min-height: 60em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Task = styled.div`
  padding: 1em 2em;
  height: 100%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 30%;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
