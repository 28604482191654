import React from 'react'
import styled from 'styled-components'
import { useMatch} from 'react-router-dom'
import { ClientsSidebar } from './ClientsSidebar';
import { LeadsSidebar } from './LeadsSidebar';
import { StaffSidebar } from './StaffSidebar';
import { AffiliateSidebar } from './AffiliateSidebar';
import { InventorySidebar } from './InventorySidebar';
import { AccountSidebar } from './AccountsSidebar';
import { TargetsSidebar } from './TargetsSidebar';
import { PoliciesSidebar } from './PoliciesSidebar';
import { ReportsSidebar } from './ReportsSidebar';
import { TrainingSidebar } from './TrainingSidebar';
import { DocumentationSidebar } from './DocumentationSidebar';

export const Sidebar = () => {
  let dashboard = useMatch('/')
  
  let login=useMatch('/login')
  let Loginmatch = false;
  if(login || dashboard){
      Loginmatch=true
  }

  let LeadsSidebarMatch = useMatch('/leads/*')

  let ClientsSidebarMatch = useMatch('/clients')

  let StaffSidebarMatch = useMatch('/staff/*')

  let AffiliateSidebarMatch = useMatch('/affiliate/*')

  let InventorySidebarMatch = useMatch('/inventory/*')

  let AccountSidebarMatch = useMatch('/accounts/*')

  let TargetsSidebarMatch = useMatch('/targets/*')

  let PoliciesSidebarMatch = useMatch('/policies/*')

  let ReportsSidebarMatch = useMatch('/reports/*')

  let TrainingSidebarMatch = useMatch('/training/*')

  let DocumentationSidebarMatch = useMatch('/documentation/*')

  return (
    <Main match={Loginmatch}>
        <Container>
          {
            LeadsSidebarMatch?.pattern?.end?
            <LeadsSidebar/>
            :
            ClientsSidebarMatch?.pattern?.end?
            <ClientsSidebar/>
            :
            StaffSidebarMatch?.pattern?.end?
            <StaffSidebar/>
            :
            AffiliateSidebarMatch?.pattern?.end?
            <AffiliateSidebar/>
            :
            InventorySidebarMatch?.pattern?.end?
            <InventorySidebar/>
            :
            AccountSidebarMatch?.pattern?.end?
            <AccountSidebar/>
            :
            TargetsSidebarMatch?.pattern?.end?
            <TargetsSidebar/>
            :
            PoliciesSidebarMatch?.pattern?.end?
            <PoliciesSidebar/>
            :
            ReportsSidebarMatch?.pattern?.end?
            <ReportsSidebar/>
            :
            TrainingSidebarMatch?.pattern?.end?
            <TrainingSidebar/>
            :
            DocumentationSidebarMatch?.pattern?.end?
            <DocumentationSidebar/>
            :null
          }
        </Container>
    </Main>
  )
}

const Main = styled.div`
display: ${props=>props.match?'none':'flex'};
width: 20vw;
height: calc(100vh - 10vh);
position: fixed;
bottom: 0;
flex-direction: column;
`



const Container = styled.div`
height: calc(100% - 10%);
padding: 1em 2em;
`
