import React, { useEffect, useRef, useState } from "react";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { store } from "../../app/store";
import { apiSlice } from "../../features/api/apiSlice";
import { logo } from "../../Shared/config";
import LogoutIcon from "@mui/icons-material/Logout";
import { useGetAllLeadsQuery } from "../../features/leadsSlice";
import { useGetDownPaymentQuery } from "../../features/accountSlice";
import { useGetMeetingsQuery } from "../../features/employeeSlice";
import { useChangePasswordMutation } from "../../features/authSlice";
import { Toaster, toast } from "react-hot-toast";
import {
  addEmp,
  notificationCount,
  postgetNotify,
  setupSocket,
  socketDisconnect,
  unsubPostGetNotify,
} from "../../services/socket";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNotification } from "../../context/notficationContext";

export const Header = () => {
  let login = useMatch("/login");
  let notFound = useMatch("/notfound");
  let changePassword = useMatch("/changePassword");
  let unAuthorized = useMatch("/unauthorized");
  let match = false;
  if (login || notFound || unAuthorized || changePassword) {
    match = true;
  }


  const [Notifications, setNotifications] = useState();

  // let isSocketConnect = useRef(false);

  // useEffect(() => {
  //   if (!isSocketConnect.current) {
  //     setupSocket();
  //     let empId = localStorage.getItem("id");
  //     let fullName = localStorage.getItem("staffName");
  //     if (empId && fullName) {
  //       addEmp(empId, fullName);
  //     }
  //     isSocketConnect.current = true;
  //   }
  // }, []);
  let empId = localStorage.getItem("id");
  let fullName = localStorage.getItem("staffName");

  useEffect(() => {
    try {
      if (empId && fullName) {
        addEmp(empId, fullName);
      }
      if (empId) {
        postgetNotify(empId);
      }
    } catch (error) {
      console.error("Error in addEmp or postgetNotify:", error);
    }
  }, [empId, fullName]);

  

  useEffect(() => {
    try {
      notificationCount((data) => {
        setNotifications(data);
      });
    } catch (error) {
      console.error("Error in notificationCount:", error);
    }
  }, []);

  const [changePass] = useChangePasswordMutation();

  let { data: leads } = useGetAllLeadsQuery();

  let {isNotification,toggleNotificationFalse} = useNotification()

  console.log(isNotification)

  let { data: downPayment } = useGetDownPaymentQuery();

  let { data: meetings } = useGetMeetingsQuery();

  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch(apiSlice.util.resetApiState());
    setdrop(false);
    navigate("/login");
  };

  const handleReset = (e) => {
    e.preventDefault();
    let data = {
      email: localStorage.getItem("email"),
    };
    changePass(data)
      .unwrap()
      .then((res) => {
        toast.success("Please check your email inbox");
        setTimeout(() => {
          window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
        }, 1500);
      })
      .catch((err) => {
        toast.error("error");
      });
  };
  const unauthorized = sessionStorage.getItem("unauthorized");
  console.log(unAuthorized);
  useEffect(() => {
    if (unauthorized === "true") {
      handleLogout();
    }
  }, [unauthorized]);

  const [drop, setdrop] = useState(false);
  return (
    <Main match={match}>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      <Container>
        <NavLink to="/">DashBoard</NavLink>
        <NavLink to="leads">Leads</NavLink>
        <NavLink to="clients">Clients</NavLink>
        <NavLink to="staff">Staff</NavLink>
        <NavLink to="affiliate">Affiliate</NavLink>
        <NavLink to="inventory">Inventory</NavLink>
        <NavLink to="accounts">Accounts</NavLink>
        <NavLink to="targets">Targets</NavLink>
        <NavLink to="reports">Reports</NavLink>
        <NavLink to="training">Training</NavLink>
        <NavLink to="policies">Policies</NavLink>
        <NavLink to="documentation">Documentation</NavLink>
      </Container>

      <NotificationTab to="/leads/notifications">
        <NotificationsActiveIcon/>
        {
          isNotification && (
            <div></div>
          )
        }
      </NotificationTab>
      <Profile onClick={() => setdrop(!drop)}>
        <Img>
          <img src={user?.profilePic} alt="" />
        </Img>
        <KeyboardArrowDownOutlinedIcon />
      </Profile>
      <Drop show={drop}>
        <ProfileHeading>Your Profile</ProfileHeading>
        <ProfilePhoto>
          <ProfilePhotoImg>
            <img src={user?.profilePic} alt="" />
          </ProfilePhotoImg>
          <ProfilePhotoText>
            <p>{user?.firstName}</p>
            <p>{user?.email}</p>
          </ProfilePhotoText>
        </ProfilePhoto>
        <ProfileHeading>Your Performance</ProfileHeading>
        <PerformanceSection>
          <div>
            <p>DownPayment</p>
            <span>{downPayment?.length}</span>
          </div>
          <div>
            <p>Leads</p>
            <span>{leads?.length}</span>
          </div>
          <div>
            <p>Meetings</p>
            <span>{meetings?.countMeeting}</span>
          </div>
        </PerformanceSection>
        <ActionPart>
          <LogOut onClick={handleLogout}>
            <LogoutIcon sx={{ color: `${colors.hot}` }} /> <span>LogOut</span>
          </LogOut>
          <Forget onClick={handleReset}>
            <LogoutIcon sx={{ color: `${colors.cold}` }} />{" "}
            <span>Change Password</span>
          </Forget>
        </ActionPart>
      </Drop>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  position: fixed;
  height: 10vh;
  width: 100%;
  display: ${(props) => (props.match ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  z-index: 10;
`;
const Logo = styled.div`
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30%;
    height: 100%;
    object-fit: contain;
  }
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
  height: 100%;
  a {
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid white;
    color: ${colors.textLight};
    transition: all 0.3s ease-out;
    font-size: clamp(1em, 1vh, 2em);
    :hover {
      color: ${colors.brown};
      border-bottom: 2px solid ${colors.brown};
    }
  }
  .active {
    color: ${colors.brown};
    border-bottom: 2px solid ${colors.brown};
  }
`;

const Profile = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Img = styled.div`
  width: 3em;
  height: 3em;
  border-radius: 100px;
  img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
`;

const Drop = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  top: 10vh;
  right: 1em;
  min-width: 4em;
  width: 30%;
  min-height: 25em;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 1em;
`;

const ProfileHeading = styled.p`
  color: ${colors.textMed};
`;

const ProfilePhoto = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfilePhotoImg = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 80px;
  img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }
`;

const ProfilePhotoText = styled.div`
  width: 70%;
`;

const PerformanceSection = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    width: 33%;
    border-right: 1px solid ${colors.textMed};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :last-child {
      border: none;
    }
    span {
      color: green;
    }
  }
`;

const ActionPart = styled.div`
  display: flex;
`;

const LogOut = styled.div`
  border-top: 1px solid ${colors.textMed};
  border-right: 1px solid ${colors.textMed};
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 4em;
  color: ${colors.hot};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-left: 0.5em;
  }
`;

const Forget = styled(LogOut)`
  border-right: none;
  color: ${colors.cold};
`;


const NotificationTab = styled(NavLink)`

display: flex;
justify-content: center;
align-items: center;
position: relative;
margin-left: 20px;
cursor: pointer;
color: black;
div{
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: ${colors.hot};
  top: -10%;
  right: -10%;
}
`
