import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  useGetDashboardReportQuery,
  useGetLeadsReportQuery,
} from "../../../features/employeeSlice";
import { useState } from "react";

export const LeadsReport = () => {
  // const {data:leadsreport} = useGetLeadsReportQuery()
  const { data: dashboard } = useGetDashboardReportQuery();

  const [leadreport, setleadreport] = useState([]);
  useEffect(() => {
    if (!dashboard) {
      return;
    }

    let sortedLeads = dashboard?.leadReport
      ?.slice()
      .sort((a, b) => a._id - b._id);

    if (!sortedLeads) {
      return;
    }

    let leadReport = [];

    sortedLeads.forEach((lead, index) => {
      leadReport.push({
        name: `${index + 1} week`,
        ...lead,
      });
    });

    setleadreport(leadReport);
  }, [dashboard]);

  // let data = []
  // leadsreport?.forEach(lead => {
  //   if(lead._id===1){
  //     data.push({name:'Jan',leads:lead.leads})
  //   }
  //   else if(lead._id===2){
  //     data.push({name:'Feb',leads:lead.leads})
  //   }
  //   else if(lead._id===3){
  //     data.push({name:'March',leads:lead.leads})
  //   }
  //   else if(lead._id===4){
  //     data.push({name:'April',leads:lead.leads})
  //   }
  //   else if(lead._id===5){
  //     data.push({name:'May',leads:lead.leads})
  //   }
  //   else if(lead._id===6){
  //     data.push({name:'June',leads:lead.leads})
  //   }
  //   else if(lead._id===7){
  //     data.push({name:'July',leads:lead.leads})
  //   }
  //   else if(lead._id===8){
  //     data.push({name:'Aug',leads:lead.leads})
  //   }
  //   else if(lead._id===9){
  //     data.push({name:'Sep',leads:lead.leads})
  //   }
  //   else if(lead._id===10){
  //     data.push({name:'Oct',leads:lead.leads})
  //   }
  //   else if(lead._id===11){
  //     data.push({name:'Nov',leads:lead.leads})
  //   }
  //   else if(lead._id===12){
  //     data.push({name:'Dec',leads:lead.leads})
  //   }
  //   else{
  //     return
  //   }
  // });
  return (
    <Main>
      <p>Leads Report</p>
      <Graph>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={leadreport}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="leads"
              stroke="#E3492D"
              fill="#e3492d0d"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Graph>
    </Main>
  );
};

const Main = styled.div`
  width: 40%;
  min-width: 30em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: ${colors.inputWhite};
  margin-top: 3em;
  padding: 1em;
  p {
    font-size: clamp(1em, 3vh, 4em);
    margin-bottom: 1em;
  }
`;
const Graph = styled.div`
  width: 100%;
  height: 80%;
`;
