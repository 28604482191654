import { apiSlice } from "./api/apiSlice";

const projectSLice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createProject:builder.mutation({
            query:(project)=>({
                url:'project/create',
                method:'POST',
                body:project
            }),
            invalidatesTags:['Project']
        }),

        getAllProjects:builder.query({
            query:()=>({
                url:'project'
            }),
            transformResponse:(response,meta,arg)=>response.project,
            providesTags:['Project']
        }),

        getSingleProject:builder.query({
            query:(id)=>({
                url:`project/${id}`
            }),
            transformResponse:(response,meta,arg)=>response.project,
            providesTags:['Project']
        }),

        deleteProject:builder.mutation({
            query:(id)=>({
                url:`project/${id}`,
                method:'DELETE'
            }),
            invalidatesTags:['Project']
        }),
        
        updateProject:builder.mutation({
            query:(id,project)=>({
                url:`project/${id}`,
                method:'PUT',
                body:project
            }),
            invalidatesTags:['Project']
        })
    })
})

export const {useGetAllProjectsQuery,useGetSingleProjectQuery,useCreateProjectMutation} = projectSLice;