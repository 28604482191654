import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { colors } from '../../Shared/colors';
import DescriptionIcon from '@mui/icons-material/Description';
import { useGetAllInventoryQuery } from '../../features/InventorySlice';
import Tooltip from "@mui/material/Tooltip";

export const InventoryDataTable = ({inventoryData}) => {
  console.log("this is inventory data",inventoryData);
  const [pageSize, setPageSize] = useState(100);

  // const {
  //   data: Inventory,
  //   isLoading
  // } = useGetAllInventoryQuery({project:"", unitType:"", projectBlock:"", unitNo:"", status:""});


  const columns = [
    {
      field:'unitNo',
      headerName:'Unit No',
      width:110,
    },
    {
      field:'project',
      headerName:'Project',
      width:110,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.project}>
            <span>{params.row.project}</span>
          </Tooltip>
        )
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 110,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.type}>
            <span>{params.row.type}</span>
          </Tooltip>
        )
      }
    },
    // {
    //   field: 'marla',
    //   headerName: 'Marla',
    //   width: 110,
    // },
    {
      field: 'marlaPrice',
      headerName: 'Marla Price',
      width: 110,
    },
    // {
    //   field: 'sqftPrice',
    //   headerName: 'SQFT Price',
    //   width: 110,
    // },
    {
      field: 'netArea',
      headerName: 'Net Area',
      width: 110,
    },
    // {
    //   field: 'crossArea',
    //   headerName: 'Cross Area',
    //   width: 110,
    // },
    {
      field: 'price',
      headerName: 'Price',
      width: 110,
    },
    {
      field: 'location',
      headerName: 'Loaction',
      width: 110,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.location}>
            <span>{params.row.location}</span>
          </Tooltip>
        )
      }
    },
    {
      field: 'plotSize',
      headerName: 'Plot Size',
      width: 110,
      renderCell:(params)=>{
        return(
          <Tooltip title={params.row.plotSize}>
            <span>{params.row.plotSize}</span>
          </Tooltip>
        )
      }
    },
    // {
    //   field: 'features',
    //   headerName: 'Features',
    //   width: 110,
    //   renderCell:(params)=>{
    //     return(
    //       <Tooltip title={params.row.features}>
    //         <span>{params.row.features}</span>
    //       </Tooltip>
    //     )
    //   }
    // },
    // {
    //   field: 'bed',
    //   headerName: 'Bed',
    //   width: 110,
    // },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell:(params)=>{
        return(
          params.row.status==='Available'?
          <Contracted color={'#66cb66d6'}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :
          params.row.status==='Token Payment'?
          <Contracted color={colors.veryCold}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :
          params.row.status==='Down Payment'?
          <Contracted color={colors.purple}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :
          params.row.status==='Partial Down Payment'?
          <Contracted color={colors.starYellow}>
            <div>
              {params.row.status}
            </div>
          </Contracted>
          :<Contracted color={'#f85a5a'}>
          <div>
            {params.row.status}
          </div>
        </Contracted>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 110,
      sortable: false ,
      filterable:false,
      hideable:false,
      renderCell:()=>{
        return(
          <Action>
            <DescriptionIcon sx={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
          </Action>
        )
      }
    },
  ];
  let rows = [];
  inventoryData?.forEach(inventory => {
    rows.push({
      id:inventory._id,
      unitNo:inventory.unitNo,
      project:inventory.project.projectName,
      type:inventory.invenType,
      bed:inventory.bed,
      price:inventory.totalPrice,
      netArea:inventory.areaInFet,
      marla:inventory.areaInMarla,
      location:inventory.location,
      crossArea:inventory.crossArea,
      plotSize:inventory.plotSize,
      features:inventory.features,
      status:inventory.status,
      sqftPrice:inventory.sqftPrice,
      marlaPrice:inventory.marlaPrice
    })
  });
  return (
    <Main>
      <LeadsSection>
      Inventory:<span> {inventoryData?.length}</span> 
      </LeadsSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20,50,100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        // loading={isLoading}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 3em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
span{
  font-weight: bold;
}
`

const Table = styled.div`
height: 90%;
`


const Contracted = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
div{
width: 60%;
background-color: ${props=>props.color};
color: white;
padding: 0.5em 0.2em;
border-radius: 5px;
text-align: center;
}
`

const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`