import React, { useState } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { colors } from "../../../Shared/colors";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { TaskTable } from "./TaskTable";

export const HierarchyDatatable = ({tableData}) => {


  const [taskPopUp, setTaskPopUp] = useState(false);

  const [leadId, setleadId] = useState("");
  const [empId, setempId] = useState("");

  const [pageSize, setPageSize] = useState(10);
    console.log("table data",tableData)


  const columns = [
    {
        field: 'clientName',
        headerName: 'Client',
        width: 150,
    },
    {
      field: "leadId",
      headerName: "Lead Id",
      width: 150,
    },
    // {
    //   field: "cnic",
    //   headerName: "Cnic",
    //   width: 150,
    // },
    {
      field: "clientOccupation",
      headerName: "Client Occupation",
      width: 150,
    },
    {
      field: "buyingIntent",
      headerName: "Buying Intent",
      width: 150,
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "project",
      headerName: "Project",
      width: 150,
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 80,
      sortable: false ,
      filterable:false,
      hideable:false,
      renderCell:(params)=>{
        return(
          <Action>
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setleadId(params.row.id);
                setempId(params.row.empId)
              }}/>
          </Action>
        )
      }
    },
  ];

  const rows = [];
  console.log("this is lead id",leadId);
  console.log("this is table data",tableData)
  tableData?.forEach((lead) => {
    rows.push({
      id: lead?._id,
      leadId: lead?.leadID,
      clientName: lead?.clientID?.fullName,
      buyingIntent:lead?.clientID?.buyingIntent,
      source:lead?.leadOpportunity?.source,
      phone: lead?.phoneNum,
      project:lead?.leadOpportunity?.project?.projectName,
      clientOccupation:lead?.clientID?.clientOccupation,
      empId:lead?.employeeID?._id
    });
  });

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Leads:<span> {tableData?.length} </span>
        </div>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TaskTable leadId={leadId} empId={empId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;


const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;