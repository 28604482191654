import React, { useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../../Shared/colors";
import { toast, Toaster } from "react-hot-toast";
import { useAssignExelClientsMutation, useGetStaffQuery } from "../../../features/employeeSlice";
import styled from "styled-components";
import Papa from "papaparse";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const ExelPopup = () => {
  const { data: staff } = useGetStaffQuery();
  const options = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );
    return options;
  }, [staff]);

  const [team, setteam] = useState(options[0]);

  const [assignClients] = useAssignExelClientsMutation()

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    let bulk = [];

    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          let newResult = results?.data?.filter((data) => data[0] !== "");
          if (newResult?.length > 201) {
            toast.error("File exceeds the maximum allowed rows (200).");
            return;
          }
          newResult?.map((site) =>
            bulk.push(site[0])
          );
          if(bulk.length === 0 && !team?.id) return
          let id = team?.id
          let data = {
            phoneNo:[...bulk]
          }
          assignClients({id,data})
              .unwrap()
              .then(() => {
                toast.success("Clients Shared successfully!");
              })
              .catch((err) => {
                toast.error(err.data.error.message);
              });
        },
      });
    }
    e.target.value = "";
  };
  return (
    <Main>
      <AutocompleteSection>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          value={team}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          onChange={(event, newvalue) => {
            setteam(newvalue);
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            } else if (
              typeof option === "object" &&
              option !== null &&
              option.hasOwnProperty("label")
            ) {
              return option.label;
            } else if (
              typeof option === "object" &&
              option !== null &&
              option.hasOwnProperty("id")
            ) {
              return option.id;
            } else {
              return "";
            }
          }}
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "2.9em",
            overflow: "hidden",
            border: `1px solid ${colors.inputBorderWhite}`,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Staff To Assign"
              sx={{ backgroundColor: `${colors.white}`, height: "2.9em" }}
            />
          )}
        />
      </AutocompleteSection>
      <InputFeild>
        <h3>
          Attachment:{" "}
          <b style={{ color: "red", fontSize: "0.8em" }}>
            maximum allowed rows (200)
          </b>
        </h3>
        <span>
          <p>upload file here</p>
          <label htmlFor="profilePic">
            <AttachFileIcon /> Upload And Assign
          </label>
        </span>
        <input
          type="file"
          name="profilePic"
          id="profilePic"
          onChange={(e) => handleFileUpload(e)}
        />
      </InputFeild>
      <Toaster/>
    </Main>
  );
};

export default ExelPopup;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;
const AutocompleteSection = styled.div``;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select,
    input[type="date"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.white};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.brown};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;
