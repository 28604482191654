import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../Shared/colors'
import {Toaster,toast} from 'react-hot-toast' 
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Shared/Loader'
import { logo } from '../Shared/config'
import { useChangePasswordCredMutation } from '../features/authSlice';
import { apiSlice } from '../features/api/apiSlice';
import { store } from '../app/store';

export const ChangePasswordComponent = () => {
  
  const [changePassword] = useChangePasswordCredMutation()
  const [visibility, setvisibility] = useState(false)
  const [loader, setloader] = useState(false)

  const navigate = useNavigate();

  const [cred, setcred] = useState({
    currentPass:'',
    newPass:'',
    retypePass:''
  })

  const handleChange = (e) => {
    const {name,value} = e.target;
    setcred((prevstate)=>({
      ...prevstate,
      [name]:value
    }))
  }

  const handleReset = (e) =>{
    e.preventDefault()
    setloader(true)
    const data = {
      oldPassword:cred.currentPass,
      newPassword:cred.newPass,
      confirmPassword:cred.retypePass
  }

  changePassword(data).unwrap()
  .then((res)=>{
    toast.success("password changed successfully")
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch(apiSlice.util.resetApiState());
    navigate("/login");
    setloader(false)
  })
  .catch((err)=>{
    toast.error("error accured while changing password")
    setloader(false)
  })

  }
  
  return (
    <Main>
      {
        loader?
        <Loader/>
        :
        null
      }
      <TopSphere></TopSphere>
      <BottomSphere></BottomSphere>
      <Container>
        <Logo>
          <img src={logo} alt="logo" />
          {/* <img src="/assets/icons/jagiir.com-logo.png" alt="" /> */}
        </Logo>
        <form>      
          <Input>
            <LockOutlinedIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5%',
                  transform: 'translateY(-50%)',
                  color: `${colors.inputFeild}`
            }}/>
            <input type={visibility?"text":"password"} name="currentPass" id="currentPassword" placeholder='currentPassword' value={cred.currentPass} onChange={handleChange} required/>
            <img src="/assets/icons/eye.svg" alt="eye" onClick={()=>setvisibility(!visibility)}/>
          </Input>
          <Input>
            <LockOutlinedIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5%',
                  transform: 'translateY(-50%)',
                  color: `${colors.inputFeild}`
            }}/>
            <input type={visibility?"text":"password"} name="newPass" id="newPassword" placeholder='New Password' value={cred.newPass} onChange={handleChange} required/>
            <img src="/assets/icons/eye.svg" alt="eye" onClick={()=>setvisibility(!visibility)}/>
          </Input>
          <Input>
            <LockOutlinedIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5%',
                  transform: 'translateY(-50%)',
                  color: `${colors.inputFeild}`
            }}/>
            <input type={visibility?"text":"password"} name="retypePass" id="retypePassword" placeholder='Retype Password' value={cred.retypePass} onChange={handleChange} required/>
            <img src="/assets/icons/eye.svg" alt="eye" onClick={()=>setvisibility(!visibility)}/>
          </Input>
          <Button onClick={handleReset}>
                Reset Password
          </Button>
        </form>
      </Container>
      <Toaster/>
    </Main>
  )
}


const Main = styled.div`
width: 100vw;
height: 100vh;
background-color: ${colors.lightWhite};
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
position: relative;

`

const Container = styled.div`
width: 40%;
min-width: 15em;
height: 50%;
background-color: ${colors.white};
box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
border-radius: 5px;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
form{
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
`

const Logo = styled.div`
width: 25%;
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
`

const Input = styled.div`
width: 100%;
position: relative;
input{
  background-color: ${colors.inputWhite};
  border: 1px solid ${colors.inputBorderWhite};
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding: 1em 0em 1em 2em;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
/* .css-i4bv87-MuiSvgIcon-root{

  } */
  img{
    position: absolute;
    top: 50%;
    right: 1.5%;
    transform: translateY(-50%);
    color: ${colors.inputFeild};
    cursor: pointer;
  }
`

const CheckBox = styled.div`
display: flex;
width: 100%;
input{
  cursor: pointer;
}
label{
  font-size: clamp(0.8em,1vw,1em);
  margin-left: 1%;
  color: ${colors.inputFeild};
  cursor: pointer;
}
`

const Button = styled.button`
width: 100%;
padding: 1em 2em;
background-color: ${colors.brown};
color: ${colors.white};
outline: none;
border: none;
border-radius: 6px;
cursor: pointer;
`

const TopSphere = styled.div`
background-color: ${colors.brown};
width: 15em;
height: 15em;
border-radius: 200px;
position: absolute;
top: -5em;
right: -5em;
`

const BottomSphere = styled.div`
background-color: ${colors.brown};
width: 15em;
height: 15em;
border-radius: 200px;
position: absolute;
left: -5em;
bottom: -5em;
`