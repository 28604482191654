import React, { useEffect } from "react";
import styled from "styled-components";
import { DashboardFirst } from "../Components/Dashboard/DashboardFirst";
import { DashboardSecond } from "../Components/Dashboard/DashboardSecond/DashboardSecond";
import { addEmp } from "../services/socket";
import { colors } from "../Shared/colors";

export const DashboardComponent = () => {

  return (
    <Main>
      <DashboardFirst />
      <DashboardSecond />
    </Main>
  );
};

const Main = styled.div`
  background-color: ${colors.inputWhite};
  min-height: calc(100vh - 10vh);
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
