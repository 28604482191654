import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {jsPDF} from 'jspdf'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { colors } from '../../../Shared/colors';
import { useGetDownPaymentQuery } from '../../../features/accountSlice';


export const DownPaymentDataTable = () => {
  const [pageSize, setPageSize] = useState(5);


  const [todoType, settodoType] = useState('all')
  const {data:downPayment,isLoading} = useGetDownPaymentQuery(todoType)
  console.log(downPayment)

  const columns = [
    {
        field: 'amount',
        headerName: 'Amount',
        width: 110,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      renderCell:(params)=>{
        let date = params.row.date;
        return(
          <div>
            {date.toDateString()}
          </div>
        )
      }
  },
    
    {
      field:'mode',
      headerName:'mode',
      width:110,
    }
    ,
    {
      field:'paymentFor',
      headerName:'Payment For',
      width:110,
    }
    ,
    {
      field:'unit',
      headerName:'Unit',
      width:90,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell:(params)=>{
          return(
            params.row.status?
            <Contracted color={'#66cb66d6'}>
              <div>
                Completed
              </div>
            </Contracted>
            :<Contracted color={'#f85a5a'}>
            <div>
              Pending
            </div>
          </Contracted>
          )
        }
      },
    {
      field:'attach',
      headerName:'Attach',
      width:110,
      renderCell:()=>{
        return(

          <InputFeild>
          <span>
            <label htmlFor="attachment">
              <AttachFileIcon/> Upload
          </label>
          </span>
          <input type="file" name="attachment" id="attachment" />
          </InputFeild>
        )
      }
    },
    // {
    //   field: 'action',
    //   headerName: 'Actions',
    //   width: 80,
    //   sortable: false ,
    //   filterable:false,
    //   hideable:false,
    //   renderCell:()=>{
    //     return(
    //       <Action>
    //         <EditIcon style={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
    //       </Action>
    //     )
    //   }
    // },
  ];

const rows = []
downPayment?.forEach(payment => {
  rows.push({
    id:payment._id,
    amount:payment.ammount,
    date:new Date(payment?.depositeDate),
    mode:payment.paymentMode,
    paymentFor:payment.projectName,
    unit:payment.unitNo,
    status:payment.approvedPayment,
  })
});
// const rows = [
//   {
//     id:1,
//     amount:'1100pkr',
//     date:'sep 7,2022',
//     mode:'Online Payment',
//     refNo:343,
//     paymentFor:'City Housing',
//     unit:123,
//     status:'completed'
//   },
//   {
//     id:2,
//     amount:'1100pkr',
//     date:'sep 7,2022',
//     mode:'Online Payment',
//     refNo:343,
//     paymentFor:'City Housing',
//     unit:123,
//     status:'completed'
//   },
//   {
//     id:3,
//     amount:'1100pkr',
//     date:'sep 7,2022',
//     mode:'Online Payment',
//     refNo:343,
//     paymentFor:'City Housing',
//     unit:123,
//     status:'completed'
//   },
//   {
//     id:4,
//     amount:'1100pkr',
//     date:'sep 7,2022',
//     mode:'Online Payment',
//     refNo:343,
//     paymentFor:'City Housing',
//     unit:123,
//     status:'completed'
//   },
// ];


const exportPdf = () => {
  var doc = new jsPDF({
    orientation: 'landscape',
    format:[500,500]
  });
  rows.forEach(function(row, i){
      doc.text(10, 10 + (i * 10), 
          "id: " + row.id +
          " amount: " + row.amount +
          " date: " + row.date +
          " mode: " + row.mode +
          " refNo: " + row.refNo +
          " paymentFor: " + row.paymentFor +
          " unit: " + row.unit +
          " status:" + row.status
          );
  });
  doc.save('Test.pdf');
}
  return (
    <Main>
    <TimeSection>
    <button
          style={{
            backgroundColor:
              todoType === "pending" ? `${colors.brown}` : `${colors.white}`,
              color: todoType === "pending" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("pending")}
        >
          pending
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "completed" ? `${colors.brown}` : `${colors.white}`,
              color: todoType === "completed" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("completed")}
        >
          completed
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "rejected" ? `${colors.brown}` : `${colors.white}`,
              color: todoType === "rejected" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("rejected")}
        >
          rejected
        </button>
        <button
          style={{
            backgroundColor:
              todoType === "all" ? `${colors.brown}` : `${colors.white}`,
              color: todoType === "all" ? `${colors.white}` : `${colors.black}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          onClick={() => settodoType("all")}
        >
          all
        </button>
    </TimeSection>
      <LeadsSection>
        <div>

      Total Down Payments:<span> {rows.length} </span> 
        </div>
        <button onClick={exportPdf}>
          <PictureAsPdfIcon/>
          Export
        </button>
      </LeadsSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={isLoading}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 1em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`
const TimeSection = styled.div`
height: 10%;
border-bottom: 1px solid ${colors.inputBorderWhite};
display: flex;
align-items: flex-end;
button{
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover{
        background-color: ${colors.brown};
        color: ${colors.white};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
width: 100%;
display: flex;
justify-content: space-between;
span{
  font-weight: bold;
}
button{
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textMed};
  padding: 1em;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 5px;
  cursor: pointer;
  font-size: clamp(0.8em,0.9vw,1em);
}
`

const Table = styled.div`
height: 80%;
`





const Contracted = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
div{
width: 60%;
background-color: ${props=>props.color};
color: white;
padding: 0.5em 0.2em;
border-radius: 5px;
text-align: center;
}
`


const InputFeild = styled.div`
width: 100%;

span{
  background-color: ${colors.inputWhite};
  display: flex;
  position: relative;
  label{
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${colors.black};
    color: ${colors.white};
    padding: 0.5em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p{
    font-size: clamp(1em,1vh,2em);
    color: ${colors.textMed};
  }
}
input[type=file]{
display: none;
}
`