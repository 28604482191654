import { apiSlice } from "./api/apiSlice";


const notificationSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        readAll:builder.mutation({
            query:()=>({
                url:'notification/read-all',
                method:'PUT',
            }),
            invalidatesTags:["Notification"]
        }),
        deleteAll:builder.mutation({
            query:()=>({
                url:'notification/delete-all',
                method:'DELETE',
            }),
            invalidatesTags:["Notification"]
        }),
        getAllNotifications:builder.query({
            query:()=>({
                url:`notification`
            }),
            transformResponse:(response,meta,arg)=>response.notification,
            providesTags:['Notification']
            
        })
    })
})


export const {useGetAllNotificationsQuery,useDeleteAllMutation,useReadAllMutation} = notificationSlice;