import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../Shared/colors'
import {Toaster,toast} from 'react-hot-toast' 
import { useLoginMutation } from '../features/authSlice'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Shared/Loader'
import { logo } from '../Shared/config'
import { setupSocket } from '../services/socket'

export const LoginComponent = () => {

  const [visibility, setvisibility] = useState(false)
  const [loader, setloader] = useState(false)

  const [loginUser] = useLoginMutation();
  const navigate = useNavigate();

  const [cred, setcred] = useState({
    email:'',
    password:'',
  })

  const handleChange = (e) => {
    const {name,value} = e.target;
    setcred((prevstate)=>({
      ...prevstate,
      [name]:value
    }))
  }

  const handleLogin = async (e) => {
    setloader(true)
    e.preventDefault();
    const credentials={
      email:cred.email,
      password:cred.password,
    }

    loginUser(credentials).unwrap()
    .then((result)=>{
      toast.success("login successful")
      console.log(result);
      localStorage.setItem('token', result.token);
      localStorage.setItem('id',result.user._id);
      localStorage.setItem('staffName',result.user.firstName+' '+result.user.lastName)
      localStorage.setItem('role',result.user.role)
      localStorage.setItem('email',result.user.email)
      localStorage.setItem('user',JSON.stringify(result.user))
      navigate('/')
      setloader(false)
    })
    .catch((error)=>{
      console.log(error)
      toast.error(error?.data?.message)
      setloader(false)
    })
    // try{
    //   let result = await loginUser(credentials)
    //   console.log("this is login user",result)
    //   if(result.data.token){
    //     toast.success('login successful')
    //     console.log(result)
    //     localStorage.setItem('token', result.data.token);
    //     localStorage.setItem('id',result.data.user._id);
    //     localStorage.setItem('staffName',result.data.user.firstName+' '+result.data.user.lastName)
    //     localStorage.setItem('role',result.data.user.role)
    //     localStorage.setItem('email',result.data.user.email)
    //     localStorage.setItem('user',JSON.stringify(result.data.user))
    //     navigate('/')
    //     setloader(false)
    //   }
      
    // }
    // catch(error){
    //   toast.error(error?.data?.error?.message)
    //   setloader(false)
    // }
    setcred({
      email:'',
      password:'',
    });
  }
  return (
    <Main>
      {
        loader?
        <Loader/>
        :
        null
      }
      <TopSphere></TopSphere>
      <BottomSphere></BottomSphere>
      <Container>
        <Logo>
          <img src={logo} alt="logo" />
          {/* <img src="/assets/icons/jagiir.com-logo.png" alt="" /> */}
        </Logo>
        <form>
          <Input>
            <MailOutlineIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5%',
                  transform: 'translateY(-50%)',
                  color: `${colors.inputFeild}`
            }}/>
            <input type="email" name="email" id="email" placeholder='E-mail' value={cred.email} onChange={handleChange}/>
          </Input>        
          <Input>
            <LockOutlinedIcon sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0.5%',
                  transform: 'translateY(-50%)',
                  color: `${colors.inputFeild}`
            }}/>
            <input type={visibility?"text":"password"} name="password" id="password" placeholder='Password' value={cred.password} onChange={handleChange}/>
            <img src="/assets/icons/eye.svg" alt="eye" onClick={()=>setvisibility(!visibility)}/>
          </Input>
          <CheckBox>
            <input type="checkbox" name="remember" id="remember" />
            <label htmlFor='remember'>Remember Password</label>
          </CheckBox>
          <Button onClick={handleLogin}>
              Log In
          </Button>
        </form>
      </Container>
      <Toaster/>
    </Main>
  )
}


const Main = styled.div`
width: 100vw;
height: 100vh;
background-color: ${colors.lightWhite};
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
position: relative;

`

const Container = styled.div`
width: 40%;
min-width: 15em;
height: 50%;
background-color: ${colors.white};
box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
border-radius: 5px;
display: flex;
justify-content: space-around;
align-items: center;
flex-direction: column;
form{
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
`

const Logo = styled.div`
width: 25%;
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
`

const Input = styled.div`
width: 100%;
position: relative;
input{
  background-color: ${colors.inputWhite};
  border: 1px solid ${colors.inputBorderWhite};
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding: 1em 0em 1em 2em;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
/* .css-i4bv87-MuiSvgIcon-root{

  } */
  img{
    position: absolute;
    top: 50%;
    right: 1.5%;
    transform: translateY(-50%);
    color: ${colors.inputFeild};
    cursor: pointer;
  }
`

const CheckBox = styled.div`
display: flex;
width: 100%;
input{
  cursor: pointer;
}
label{
  font-size: clamp(0.8em,1vw,1em);
  margin-left: 1%;
  color: ${colors.inputFeild};
  cursor: pointer;
}
`

const Button = styled.button`
width: 100%;
padding: 1em 2em;
background-color: ${colors.brown};
color: ${colors.white};
outline: none;
border: none;
border-radius: 6px;
cursor: pointer;
`

const TopSphere = styled.div`
background-color: ${colors.brown};
width: 15em;
height: 15em;
border-radius: 200px;
position: absolute;
top: -5em;
right: -5em;
`

const BottomSphere = styled.div`
background-color: ${colors.brown};
width: 15em;
height: 15em;
border-radius: 200px;
position: absolute;
left: -5em;
bottom: -5em;
`