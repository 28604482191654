// import io from "socket.io-client";

// // const socket = io("https://jagiir-propware-staging.onrender.com/",
// // export const socket = io("http://192.168.100.25:5056/",
// export const socket = io("https://propware-jagiir.onrender.com/");




// const setupSocket = () => {
//   socket.on("connect", () => {
//     console.log("connected to server");
//   });
// };

// const socketDisconnect = () => {
//   socket.disconnect();
// };
// const subscribeToNotifications = (callback) => {
//   socket.on("newNotification", callback);
// };
// const broadcastPolicyMessage = (callback) => {
//   socket.on("broadcastPolicyMessage", callback);
// };

// const unsubscribeFromNotifications = () => {
//   socket.off("newNotification");
// };

// const postgetNotify = (empId) =>{
//   socket.emit("getNotify",{empId})
// }

// const unsubPostGetNotify = () =>{
//   socket.off("getNotify")
// }

// const notificationCount = (callback) =>{
//   socket.on("notificationCount",callback)
// }
// const addEmp = (empId,fullName) =>{
//     socket.emit("addEmp",{empId,fullName})
// }

// const newPolicy = (newMessage) =>{
//     socket.emit("policyNotification",{newMessage})
// }
// const unSubNewPolicy = () =>{
//     socket.off("policyNotification")
// }

// const duplicateEmployee = (empId,type,message) => {
//     socket.emit("searchClientNotification", {empId,type,message})
// }

// export {
//   setupSocket,
//   socketDisconnect,
//   subscribeToNotifications,
//   unsubscribeFromNotifications,
//   addEmp,
//   duplicateEmployee,
//   newPolicy,
//   unSubNewPolicy,
//   broadcastPolicyMessage,
//   postgetNotify,
//   notificationCount,
//   unsubPostGetNotify
// };

import io from "socket.io-client";

// const socket = io("http://192.168.100.25:5056/");
// const socket = io("https://jagiir-propware-staging.onrender.com");
const socket = io("https://propware-jagiir.onrender.com/");

/**
 * Setup socket connection and handle 'connect' event.
 */
const setupSocket = () => {
  socket.on("connect", () => {
    console.log("Connected to server");
  });
};

/**
 * Disconnect from the socket.
 */
const socketDisconnect = () => {
  socket.disconnect();
};

/**
 * Subscribe to 'newNotification' events.
 * @param {Function} callback - Callback function to handle new notifications.
 */
const subscribeToNotifications = (callback) => {
  socket.on("newNotification", callback);
};

/**
 * Unsubscribe from 'newNotification' events.
 */
const unsubscribeFromNotifications = () => {
  socket.off("newNotification");
};

/**
 * Add a new employee.
 * @param {string} empId - Employee ID.
 * @param {string} fullName - Full name of the employee.
 */
const addEmp = (empId, fullName) => {
  socket.emit("addEmp", { empId, fullName });
};

/**
 * Notify about a new policy.
 * @param {string} newMessage - Message about the new policy.
 */
const newPolicy = (newMessage) => {
  socket.emit("policyNotification", { newMessage });
};

/**
 * Unsubscribe from 'policyNotification' events.
 */
const unSubNewPolicy = () => {
  socket.off("policyNotification");
};

/**
 * Fetch notifications for a specific employee.
 * @param {string} empId - Employee ID.
 */
const postgetNotify = (empId) => {
  socket.emit("getNotify", { empId });
};

/**
 * Unsubscribe from 'getNotify' events.
 */
const unsubPostGetNotify = () => {
  socket.off("getNotify");
};

/**
 * Get the count of notifications.
 * @param {Function} callback - Callback function to handle notification count.
 */
const notificationCount = (callback) => {
  socket.on("notificationCount", callback);
};

const duplicateEmployee = (empId,type,message) => {
    socket.emit("searchClientNotification", {empId,type,message})
}

const broadcastPolicyMessage = (callback) => {
  socket.on("broadcastPolicyMessage", callback);
};

const meetingNotification = (callback) => {
  socket.on("meetingNotification", callback);
}
export {
  setupSocket,
  socketDisconnect,
  subscribeToNotifications,
  unsubscribeFromNotifications,
  addEmp,
  newPolicy,
  unSubNewPolicy,
  postgetNotify,
  notificationCount,
  unsubPostGetNotify,
  duplicateEmployee,
  broadcastPolicyMessage,
  meetingNotification
};
