
import React from 'react'

export const Unauthorized = () => {
    return (
        <h1>
            UnAuthorized
        </h1>
      )
    }
