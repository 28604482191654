import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useAddClientLeadTaskMutation } from "../../../features/taskSlice";
import { Puff } from "react-loader-spinner";
import { toast, Toaster } from "react-hot-toast";
import {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
} from "../../../features/projectSlice";
import { useLazyGetInventoryByProjectIdQuery } from "../../../features/InventorySlice";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Banks} from '../../../Shared/Banks'

export const AddNewLeadsThird = ({ client, lead, reset }) => {
  const [subType, setsubType] = useState();

  const [tokenDownPayment, settokenDownPayment] = useState(false);

  const [loading, setloading] = useState(false);

  const [closedLost, setclosedLost] = useState(false);

  const [doNothing, setdoNothing] = useState(false);

  const [minDate, setminDate] = useState('')

  const [maxDate, setmaxDate] = useState("")

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultDate = new Date(date).toISOString().split("T")[0];
  
const minNumberOfDaysToAdd = -1;
const newMinDate = new Date(today.setDate(today.getDate() + minNumberOfDaysToAdd)).toISOString().split("T")[0];
  
useEffect(() => {
  setminDate(newMinDate);
}, [newMinDate]);

  useEffect(() => {
    setmaxDate(defaultDate)
  }, [defaultDate])
  
  const [task, settask] = useState({
    currentTaskType: "",
    currentSubTypeTask: "",
    completedDate: defaultDate,
    reason: "",
    comment: "",
    nextTaskType: "",
    deadline: "",
    DProjectID: "",
    DTprojectBlockName: "",
    invenID: "",
    ammount: "",
    paymentMode: "",
    bankName: "",
    transactionNo: "",
    recieptNo: "",
    depositeDate: "",
    status: "",
    attachment: "",
    approvedPayment: "",
  });

  const [addClientLeadTask, { isLoading }] = useAddClientLeadTaskMutation();

  let { data: project } = useGetAllProjectsQuery();

  let { data: singleProject } = useGetSingleProjectQuery(task.DProjectID);

  const [trigger, { data: inventory }] = useLazyGetInventoryByProjectIdQuery();

  useEffect(() => {
    if (singleProject) {
      setloading(false);
    }
  }, [singleProject]);

  useEffect(() => {
    if (task.DProjectID || task.DTprojectBlockName) {
      const project = task.DProjectID;
      const block = task.DTprojectBlockName;
      trigger({ project, block });
    }
  }, [task.DProjectID, task.DTprojectBlockName, trigger]);

  let inventoryData = [];
  inventory?.forEach((inven) => {
    if (inven.status === "Available") {
      inventoryData.push({
        unitNo: inven.unitNo,
        id: inven._id,
      });
    }
  });

  const handleProject = (e) => {
    let { name, value } = e.target;
    setloading(true);
    settask({ ...task, DTprojectBlockName: "" });
    settask({ ...task, [name]: value });
  };

  let handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "nextTaskType") {
      setdoNothing(false);
      if (value === "doNothing") {
        setdoNothing(true);
      }
    }
    settask({ ...task, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    settask((prevstate) => ({
      ...prevstate,
      [name]: e.target.files[0],
    }));

    e.target.files = "";
  };

  const handleType = (e) => {
    if (e.target.value === "Call") {
      setsubType([
        "SMS",
        "Call connected",
        "Call Not Connected",
        "Whatsapp",
        "Followed Up",
      ]);
    } else if (e.target.value === "Meeting") {
      setsubType(["Vedio Meeting","Head Office Meeting" ,"Outdoor", "Site", "Walk In"]);
    } else if (e.target.value === "Sales") {
      setsubType([
        "Token Payment",
        "CLosed (Won)",
        "Closed (Lost)",
        "Complete Down Payment",
      ]);
    }
    handleChange(e);
  };

  const handleSubtype = (e) => {
    settokenDownPayment(false);
    setclosedLost(false);
    if (
      e.target.value === "Token Payment" ||
      e.target.value === "Complete Down Payment"
    ) {
      settokenDownPayment(true);
    }
    if (e.target.value === "Closed (Lost)") {
      setclosedLost(true);
    }
    handleChange(e);
  };

  const handleSave = () => {
    var formdata = new FormData();
    console.log(client, lead, task);
    formdata.append("fullName", client.fullName);
    formdata.append("gender", client.gender);
    formdata.append("fatherName", client.fatherName);
    formdata.append("affiliate",client.affName)
    formdata.append("cnic", client.cnic);
    formdata.append("passportNo", client.passportNo);
    formdata.append("email", client.email);
    formdata.append("landLineNumber", client.landline);
    formdata.append("country", client.country);
    formdata.append("dob", client.dob);
    formdata.append("phoneNo", JSON.stringify(client.phoneNo));
    formdata.append("clientType", client.clientType);
    formdata.append("clientOccupation", client.clientOccupation);
    formdata.append("clientRating", client.clientRating);
    formdata.append("buyingIntent", client.buyingIntent);
    formdata.append("leadInterestedProjectID", lead.leadInterestedProjectID);
    formdata.append("classification", lead.classification);
    formdata.append("propertyType", lead.propertyType);
    formdata.append("subType", lead.subType);
    formdata.append("source", lead.source);
    formdata.append("budgetPer", lead.budgetPer);
    formdata.append("minPrice", lead.minPrice);
    formdata.append("maxPrice", lead.maxPrice);
    formdata.append("landArea", lead.landArea);
    formdata.append("beds", lead.beds);
    formdata.append("baths", lead.baths);
    formdata.append("constructionStatus", lead.constructionStatus);
    formdata.append("subject", lead.subject);
    formdata.append("leadmessage", lead.leadmessage);
    formdata.append("leadcomment", lead.leadcomment);
    formdata.append("currentTaskType", task.currentTaskType);
    formdata.append("currentSubTypeTask", task.currentSubTypeTask);
    formdata.append("completedDate", task.completedDate);
    formdata.append("reason", task.reason);
    formdata.append("comment", task.comment);
    formdata.append("nextTaskType", task.nextTaskType);
    formdata.append("deadline", task.deadline);
    formdata.append("attachment", task.attachment);
    formdata.append("DProjectID", task.DProjectID);
    formdata.append("DTprojectBlockName", task.DTprojectBlockName);
    formdata.append("invenID", task.invenID);
    formdata.append("ammount", task.ammount);
    formdata.append("paymentMode", task.paymentMode);
    formdata.append("bankName", task.bankName);
    formdata.append("transactionNo", task.transactionNo);
    formdata.append("recieptNo", task.recieptNo);
    formdata.append("depositeDate", task.depositeDate);
    formdata.append("status", task.status);
    formdata.append("approvedPayment", task.approvedPayment);
    console.log('this is lead data',client, lead, task);
    addClientLeadTask(formdata)
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success("client lead and task created successfully");
        settask({
          currentTaskType: "",
          currentSubTypeTask: "",
          completedDate: "",
          reason: "",
          comment: "",
          nextTaskType: "",
          deadline: "",
          DProjectID: "",
          DTprojectBlockName: "",
          invenID: "",
          ammount: "",
          paymentMode: "",
          bankName: "",
          transactionNo: "",
          recieptNo: "",
          depositeDate: "",
          status: "",
          attachment: "",
          approvedPayment: "",
        });
        reset();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.error?.message);
      });
  };



  return (
    <Main>
      <Section>
        <Task>
          <Heading>Add Task</Heading>
          <InputPart>
            <h3>Current Task</h3>
            <InputFeild>
              <h3>
                Type: <b style={{ color: "red" }}>*</b>
              </h3>
              <div>
                <select
                  name="currentTaskType"
                  id="currentTaskType"
                  value={task.currentTaskType}
                  onChange={handleType}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="Call">Call</option>
                  <option value="Meeting">Meeting</option>
                  <option value="Sales">Sales</option>
                </select>
              </div>
            </InputFeild>
            <InputFeild>
              <h3>
                Sub-Type: <b style={{ color: "red" }}>*</b>
              </h3>
              <div>
                <select
                  name="currentSubTypeTask"
                  id="currentSubTypeTask"
                  value={task.currentSubTypeTask}
                  onChange={handleSubtype}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {subType?.map((ls) => (
                    <option value={ls} key={ls}>
                      {ls}
                    </option>
                  ))}
                </select>
              </div>
            </InputFeild>
            <InputFeild>
              <h3>
                Completed Date: <b style={{ color: "red" }}>*</b>
              </h3>
              <div>
                <input
                  type="date"
                  name="completedDate"
                  id="completedDate"
                  value={task.completedDate}
                  onChange={handleChange}
                  min={minDate}
                  max={maxDate}
                />
              </div>
            </InputFeild>
            {closedLost ? (
              <InputFeild>
                <h3>
                  Reason: <b style={{ color: "red" }}>*</b>
                </h3>
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Type Here..."
                  value={task.comment}
                  onChange={handleChange}
                />
              </InputFeild>
            ) : null}
            {tokenDownPayment === false ? (
              <>
                <InputFeild>
                  <h3>
                    Comment: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <textarea
                    name="comment"
                    id="comment"
                    placeholder="type here..."
                    value={task.comment}
                    onChange={handleChange}
                  ></textarea>
                </InputFeild>
                {
                  task.currentTaskType==='Meeting' || task.currentTaskType==="Sales" || task.currentSubTypeTask==="Whatsapp"?
                  <InputFeild>
                  <h3>
                    Attachment: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <span>
                    <p>upload file here</p>
                    <label htmlFor="attachment">
                      <AttachFileIcon /> Upload
                    </label>
                    {task.attachment && (
                    <UploadedFile
                      src={URL.createObjectURL(task.attachment)}
                      alt="uploaded file"
                    />
                  )}
                  </span>
                  <input
                    type="file"
                    name="attachment"
                    id="attachment"
                    onChange={handleFileChange}
                  />
                  
                </InputFeild>:null
                }
              </>
            ) : null}
            {tokenDownPayment ? (
              <>
                <InputFeild>
                  <h3>
                    Project: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <div>
                    <select
                      name="DProjectID"
                      id="DProjectID"
                      onChange={handleProject}
                    >
                      <option value="">Select Project</option>
                      {project?.map((pro) => (
                        <option value={pro._id}>{pro.projectName}</option>
                      ))}
                    </select>
                  </div>
                </InputFeild>
                {loading ? (
                  <Loader>
                    <Puff
                      height="40"
                      width="40"
                      radius={1}
                      color={colors.brown}
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </Loader>
                ) : null}
                {singleProject?.projectBlock?.length > 0 ? (
                  <InputFeild>
                    <h3>
                      Project Block: <b style={{ color: "red" }}>*</b>
                    </h3>
                    <div>
                      <select
                        name="DTprojectBlockName"
                        id="DTprojectBlockName"
                        onChange={handleChange}
                      >
                        <option value="">select</option>
                        {singleProject?.projectBlock?.map((pro) => (
                          <option value={pro.blockName}>{pro.blockName}</option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                ) : null}
                <InputFeild>
                  <h3>
                    Unit: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <div>
                    <select name="invenID" id="invenID" onChange={handleChange}>
                      <option value="" disabled selected>
                        Select
                      </option>
                      {inventoryData?.map((inven) => (
                        <option value={inven.id}>{inven.unitNo}</option>
                      ))}
                    </select>
                  </div>
                </InputFeild>
                <Heading>
                  Add Payment: <b style={{ color: "red" }}>*</b>
                </Heading>
                <InputFeild>
                  <h3>Amount:</h3>
                  <input
                    type="text"
                    name="ammount"
                    id="ammount"
                    value={task.ammount}
                    onChange={handleChange}
                  />
                </InputFeild>
                <InputFeild>
                  <h3>
                    Payment Mode: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <div>
                    <select
                      name="paymentMode"
                      id="paymentMode"
                      onChange={handleChange}
                    >
                      <option value="" >
                        Select
                      </option>
                      <option value="Bank Transfer">Bank Transfer</option>
                    </select>
                  </div>
                </InputFeild>
                <BankInputFeild>
                  <h3>
                    Bank Name: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Banks}
                    value={task.bankName}
                    onChange={(event,newvalue)=>settask({...task,bankName:newvalue})}
                    sx={{ width: '100%',border:`1px solid ${colors.inputBorderWhite}`,borderRadius:'5px' }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{backgroundColor:`${colors.inputWhite}`}}/>
                    )}
                  />
                </BankInputFeild>
                <InputFeild>
                  <h3>
                    Transaction No: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <input
                    type="text"
                    name="transactionNo"
                    id="transactionNo"
                    value={task.transactionNo}
                    onChange={handleChange}
                  />
                </InputFeild>
                <InputFeild>
                  <h3>
                    Reciept No: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <input
                    type="text"
                    name="recieptNo"
                    id="recieptNo"
                    value={task.recieptNo}
                    onChange={handleChange}
                  />
                </InputFeild>
                <InputFeild>
                  <h3>
                    Deposite Date: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <div>
                    <input
                      type="date"
                      name="depositeDate"
                      id="depositeDate"
                      value={task.depositeDate}
                      onChange={handleChange}
                    />
                  </div>
                </InputFeild>
                <InputFeild>
                  <h3>
                    Attach Payment Proof: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <span>
                    <p>upload file here</p>
                    <label htmlFor="approvedPayment">
                      <AttachFileIcon /> Upload
                    </label>
                    {task.approvedPayment && (
                    <UploadedFile
                      src={URL.createObjectURL(task.approvedPayment)}
                      alt="uploaded file"
                    />
                  )}
                  </span>
                  <input
                    type="file"
                    name="approvedPayment"
                    id="approvedPayment"
                    onChange={handleFileChange}
                  />
                  <p>
                    Note: Invalid payment proof will result in cancellation of
                    payment and inventory will released immediately.
                  </p>
                </InputFeild>
                <InputFeild>
                  <h3>
                    Comment: <b style={{ color: "red" }}>*</b>
                  </h3>
                  <textarea
                    name="comment"
                    id="comment"
                    placeholder="type here..."
                    value={task.comment}
                    onChange={handleChange}
                  ></textarea>
                </InputFeild>
                <FixedContainer>
                  <Price>
                    <h4>Unit Price:</h4>
                    <p>PKR 1230000</p>
                  </Price>
                  <InputFeild>
                    <h3>Unit Status:</h3>
                    <div>
                      <select
                        name="status"
                        id="status"
                        value={task.status}
                        onChange={handleChange}
                      >
                        <option value="downPayment" disabled selected>
                          downPayment
                        </option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Unit Discount</h3>
                    <input
                      type="text"
                      value={"0"}
                      name="uniDiscount"
                      id="unitDiscount"
                      disabled
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Deal Price</h3>
                    <input
                      type="text"
                      value={"342324"}
                      name="dealPrice"
                      id="dealPrice"
                      disabled
                    />
                  </InputFeild>
                  <Price>
                    <h4>Due Amount:</h4>
                    <p>PKR 1230000</p>
                  </Price>
                </FixedContainer>
              </>
            ) : null}
          </InputPart>
        </Task>
        <ButtonPart>
          <button>Cancel</button>
        </ButtonPart>
      </Section>
      <Section>
        <Task style={{ borderRight: "none" }}>
          <Heading>Add Task</Heading>
          <InputPart>
            <h3>Next Task</h3>
            <InputFeild>
              <h3>
                Next Task: <b style={{ color: "red" }}>*</b>
              </h3>
              <div>
                <select
                  name="nextTaskType"
                  id="nextTaskType"
                  value={task.nextTaskType}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="doNothing">Do Nothing</option>
                  <option value="followUp">Follow Up</option>
                  <option value="contractedClient">Contracted Client</option>
                  <option value="meetClient">Meet Client</option>
                  <option value="recievePartialDownPayment">
                    Recieve Partial Down Payment
                  </option>
                  <option value="recieveCompleteDownPayment">
                    Recieve Complete Down Payment
                  </option>
                  <option value="arrangeMeeting">Arrange Meeting</option>
                  <option value="receivetokenPayment">
                    Receive Token Payment
                  </option>
                </select>
              </div>
            </InputFeild>
            {doNothing === false ? (
              <InputFeild>
                <h3>Deadline:</h3>
                <div>
                  <input
                    type="datetime-local"
                    name="deadline"
                    id="deadline"
                    value={task.deadline}
                    onChange={handleChange}
                    min={new Date().toISOString().slice(0,16)}
                  />
                </div>
              </InputFeild>
            ) : null}
          </InputPart>
        </Task>
        <ButtonPart>
          <button
            style={{
              backgroundColor: `${colors.textBlue}`,
              color: `${colors.white}`,
              opacity: isLoading ? "0.5" : "1",
            }}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? <div>saving...</div> : <div>save</div>}
          </button>
        </ButtonPart>
      </Section>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  margin-top: 3em;
  margin-bottom: 3em;
  width: 90%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  border-bottom: 1px solid ${colors.inputFeild};
  border-right: 1px solid ${colors.inputFeild};
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;

const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  color: ${colors.textBlue};
  font-size: clamp(1.5em, 2vh, 3em);
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="datetime-local"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const FixedContainer = styled.div`
  width: 100%;
  min-height: 3em;
  margin-top: 1em;
  background-color: ${colors.inputBorderWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const Price = styled.div`
  display: flex;
  p {
    margin-left: 2em;
  }
  margin-top: 1em;
`;

const Loader = styled.div`
  margin-top: 1em;
`;


const BankInputFeild = styled.div`
width: 100%;
h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`

const UploadedFile = styled.img`
    border-radius: 50%;
    margin-left: 50%;
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`