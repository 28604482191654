import React from 'react'
import styled from 'styled-components'
import { MalkiyetContactFirst } from '../../Components/Staff/MalkiyetContact/MalkiyetContactFirst'
import { colors } from '../../Shared/colors'

export const MalkiyetContactComponent = () => {
  return (
    <Main>
        <MalkiyetContactFirst/>
    </Main>
  )
}


const Main = styled.div`
background-color: ${colors.inputWhite};
min-height: calc(100vh - 10vh);
margin-top: 10vh;
display: flex;
padding-top: 2em;
flex-direction: column;
justify-content: center;
align-items: center;
`