import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'

export const DocumentationFirst = () => {
  return (
    <Main>
        <Search>
            <SubHeading>
                Documentation
            </SubHeading>
            <SearchSection1>
                <InputSection>
                <h3>Search By:</h3>
                <div>
                <select name="SearchBy" id="SearchBy">
                    <option value="">Select</option>
                </select>
                </div>
                </InputSection>
                <InputSection>
                <h3>Project:</h3>
                <div>
                <select name="project" id="project">
                    <option value="">Select</option>
                </select>
                </div>
                </InputSection>
                <InputSection>
                <h3>date By:</h3>
                <div>
                  <input type="date" name="dateBy" id="dateBy" />
                </div>
                </InputSection>
            </SearchSection1>
            <SearchSection2>
                <InputSection>
                <h3>Date Range:</h3>
                <div>
                  <input type="date" name="dateRange" id="dateRange" />
                </div>
                </InputSection>
                <Button>
                    Search
                </Button>
            </SearchSection2>
        </Search>
    </Main>
  )
}


const Main = styled.div`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
min-height: 30vh;
`

const Search = styled.div`
background-color: ${colors.inputWhite};
width: 100%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 1em;
`


const SubHeading = styled.h4`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1.2em,1.2vw,1.5em);
border-bottom: 1px solid ${colors.inputBorderWhite};
padding-bottom: 1em;
`

const SearchSection1 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
`

const SearchSection2 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
align-items: flex-end;
`


const InputSection = styled.div`
min-width: 2em;
width: 20vw;
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.white};
  select,input[type=date]{
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
}
input{
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
`

const Button = styled.button`
padding: 1em 2em;
color: ${colors.white};
background-color: ${colors.brown};
border: none;
border-radius: 5px;
cursor: pointer;
`