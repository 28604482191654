import { apiSlice } from "./api/apiSlice";

const leadSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        addLead:builder.mutation({
            query:(lead)=>({
                url:'lead/create',
                method:'POST',
                body:lead
            }),
            invalidatesTags:["Leads"]
        }),
        getAllLeads:builder.query({
            query:()=>({
                url:'lead/emp'
            }),
            transformResponse:(response,meta,arg)=>response.leads,
            providesTags:['Leads']
        }),
        getFavouriteLeads:builder.query({
            query:()=>({
                url:'/employee/favourite'
            }),
            transformResponse:(response,meta,arg)=>response.leads.favouriteLead,
            providesTags:['Leads']
        }),
        getSharedLeads:builder.query({
            query:()=>({
                url:'/leadSharing'
            }),
            transformResponse:(response,meta,arg)=>response.leadSharingData,
            providesTags:['Leads']
        }),
        getCalender:builder.query({
            query:()=>({
                url:'task/calender'
            }),
            transformResponse:(response)=>response.calender
        }),
        getTodoListTask:builder.query({
            query:(option)=>({
                url:`/task/overduetask?task=${option}`
            }),
            transformResponse:(response)=>response.tasks,
            providesTags:['Task']
        }),
        getSingleEmployeeLeads:builder.query({
            query:(id)=>({
                url:`/lead/emp/${id}`
            }),
            transformResponse:(response)=>response.empLeads
        }),
        searchLeads:builder.query({
            query:({start,end,clientID,from,projectID,employeeID,classification,source,numTasks})=>({
                url:`/lead/manage_lead/filter?clientID=${clientID}&from=${from}&projectID=${projectID}&employeeId=${employeeID}&classification=${classification}&source=${source}&numTasks=${numTasks}`,
            }),
            transformResponse:(response,meta,arg)=>response.leads,
            providesTags:['Leads']
        }),
        searchLeadsFilter:builder.query({
            query:({start,end,clientID,projectID,employeeID,leadID,page,pageSize,phoneNumber})=>({
                url:`/lead/filter/leads?startDate=${start}&endDate=${end}&clientID=${clientID}&projectID=${projectID}&employeeId=${employeeID}&leadID=${leadID}&page=${page}&pageSize=${pageSize}&phoneNumber=${phoneNumber}`,
            }),
            transformResponse:(response,meta,arg)=>response.leads,
            providesTags:['Leads']
        }),
        shareLead:builder.mutation({
            query:(lead)=>({
                url:'leadSharing/create',
                method:'POST',
                body:lead
            }),
            invalidatesTags:["Leads"]
        }),
        addFeedback:builder.mutation({
            query:({leadId,body})=>({
                url:`/lead/${leadId}/feedback`,
                method:'PUT',
                body
            }),
            invalidatesTags:["Leads"]
        })
    })
})

export const {useGetSharedLeadsQuery,useShareLeadMutation,useAddLeadMutation,useGetAllLeadsQuery,useGetFavouriteLeadsQuery,useGetCalenderQuery,useGetTodoListTaskQuery,useLazyGetSingleEmployeeLeadsQuery,useLazySearchLeadsQuery,useLazySearchLeadsFilterQuery,useAddFeedbackMutation} = leadSlice;