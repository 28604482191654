import React from 'react'
import styled from 'styled-components'
import {colors} from '../../../Shared/colors'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useGetQuarterlyLeadTaskQuery } from '../../../features/employeeSlice';

export const QuarterlyLead = () => {

  const {data:quarterlyLead,isSuccess} = useGetQuarterlyLeadTaskQuery()

  let data = []

  quarterlyLead?.forEach(lead => {
    if(lead.month===1){
      data.push({name:"1st",lead:lead.count})
    }
    else if(lead.month===2){
      data.push({name:"2nd",lead:lead.count})
    }
    else if(lead.month===3){
      data.push({name:"3rd",lead:lead.count})
    }
    else if(lead.month===4){
      data.push({name:"4th",lead:lead.count})
    }
    else{
      return
    }
    
  });
  return (
    <Main>
      <p>Quarterly Lead Task {isSuccess?quarterlyLead[0]?.year:null}</p>
    <Graph>
        <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="lead" stroke="#E3492D" fill="#e3492d0d" />
        </AreaChart>
      </ResponsiveContainer>
      </Graph>
      </Main>
  )
}


const Main = styled.div`
width: 40%;
min-width: 30em;
height: 15em;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
background-color: ${colors.inputWhite};
margin-top: 3em;
padding:1em;
p{
  font-size: clamp(1em,3vh,4em);
  margin-bottom: 1em;
}

`
const Graph = styled.div`
width: 100%;
height: 80%;
`
