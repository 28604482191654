import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Puff } from "react-loader-spinner";

import { toast, Toaster } from "react-hot-toast";
import { colors } from "../../../Shared/colors";
import { useGetAllRegionQuery } from "../../../features/regionSlice";
import { useCreateProjectMutation } from "../../../features/projectSlice";

export const ProjectsFirst = () => {
  const [popUp, setpopUp] = useState(false);

  const [projectBlock, setProjectBlock] = useState([]);
  const [projectBlockObj, setProjectBlockObj] = useState({
    blockName: "",
    blockType: "",
  });
  const [Project, setProject] = useState({
    projectName: "",
    projectType: "",
    region: "",
    location: "",
    projectBlock: [],
  });

  useEffect(() => {
    setProject((prevState) => ({
      ...prevState,
      projectBlock: projectBlock,
    }));
    console.log(Project);
  }, [projectBlock]);



  let { data: region } = useGetAllRegionQuery();
  console.log(region)

  let [addProject,{isLoading}]= useCreateProjectMutation()


  const handleProjectBlock = () => {
    if (projectBlockObj.blockName && projectBlockObj.blockType) {
      const updatedProjectBlock = [
        ...projectBlock,
        {
          blockName: projectBlockObj.blockName,
          blockType: projectBlockObj.blockType,
        },
      ];
      setProject((prevState) => ({
        ...prevState,
        projectBlock: updatedProjectBlock,
      }));
      setProjectBlock(updatedProjectBlock);
      setProjectBlockObj({
        blockName: "",
        blockType: "",
      });
    }
  };


  let projectType = [
    "vaccant land",
    "residential",
    "commercial",
    "industrial",
    "agricultural",
    "residential/commercial",
    "apartment",
    "home",
    "plot",
  ];


  const handleProjectBlockObj = (e) => {
    const { name, value } = e.target;
    setProjectBlockObj({
      ...projectBlockObj,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "blockName" || name === "blockType") {
      setProject((prevState) => ({
        ...prevState,
        projectBlock: {
          ...prevState.projectBlock,
          [name]: value,
        },
      }));
    } else {
      setProject((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSave = (e) => {
    if (projectBlockObj.blockName && projectBlockObj.blockType){
        handleProjectBlock()
    }
    addProject(Project).unwrap()
    .then(() => {
      toast.success("project added successfully");
    })
    .catch((err) => {
      toast.error(err.data.error.message);
    });
    setProject({
        projectName: "",
        projectType: "",
        region: "",
        location: "",
        projectBlock: [],
      });

      setProjectBlock([])
  };

  return (
    <Main>
      <Search>
        <AffiliateSection>
          <SubHeading>Add Project</SubHeading>
        </AffiliateSection>
        <SearchSection1>
          <InputSection>
            <h3>Name:</h3>
            <input type="text" name="projectName" value={Project.projectName} onChange={handleChange} />
          </InputSection>
          <InputSection>
            <h3>Type:</h3>
            <div>
              <select
                name="projectType"
                id="projectType"
                onChange={handleChange}
                value={Project.projectType}
              >
                <option value="">Select</option>
                {projectType.map((ls) => (
                  <option value={ls}>{ls}</option>
                ))}
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Region:</h3>
            <div>
              <select name="region" id="region" onChange={handleChange} value={Project.region}>
                <option value="">select</option>
                {region?.map((ls) => (
                  <option value={ls._id}>{ls.regionName}</option>
                ))}
              </select>
            </div>
          </InputSection>
        </SearchSection1>
        <SearchSection2>
          <InputSection>
            <h3>Location:</h3>
            <input type="text" name="location" onChange={handleChange} value={Project.location}/>
          </InputSection>
          <InputSection>
            <h3>Block Name:</h3>
            <input
              type="text"
              name="blockName"
              value={projectBlockObj.blockName}
              onChange={handleProjectBlockObj}
            />
          </InputSection>
          <InputSection>
            <h3>Block Type:</h3>
            <input
              type="text"
              name="blockType"
              value={projectBlockObj.blockType}
              onChange={handleProjectBlockObj}
            />
          </InputSection>
          <Plus onClick={handleProjectBlock}>
            <img src="/assets/icons/plus.png" alt="plus" />
          </Plus>
          <Button onClick={handleSave}>
                    {
                        isLoading?
                        <>...Adding</>:<>Add</>
                    }
            </Button>
        </SearchSection2>
      </Search>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const AffiliateSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  width: 8em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  overflow: scroll;
`;

const Cross = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.form`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Section = styled.div`
  width: 50%;
  min-height: 100em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Task = styled.div`
  padding: 1em 2em;
  height: 100%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const Loader = styled.div`
  margin-top: 1em;
`;

const Plus = styled.div`
  width: 2%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
