import React from 'react'
import { Outlet, useLocation , Navigate} from 'react-router-dom'

export const RequireAuth = ({allowedRoles}) => {
    const role = localStorage.getItem('role')
    const location = useLocation();
  return (
    allowedRoles?.includes(role)
    ?
      <Outlet/>:
    role?
        <Navigate to='/unauthorized' state={{from: location}} replace />
    :<Navigate to='/login' state={{from: location}} replace />
  )
}
