import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import AddIcon from "@mui/icons-material/Add";
import { useCreatePolicyMutation } from "../../features/policySlice";
import { Toaster, toast } from "react-hot-toast";
import { newPolicy, unSubNewPolicy } from "../../services/socket";

export const PoliciesFirst = () => {
  const [popUp, setpopUp] = useState(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false)
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const [policy, setpolicy] = useState({
    policyHeading: "",
    policyDescription: "",
    effectiveFrom: "",
  });

  const [addPolicy, { isLoading }] = useCreatePolicyMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setpolicy({
      ...policy,
      [name]: value,
    });
  };

  const handleSave = () => {
    addPolicy(policy)
      .unwrap()
      .then((res) => {
        toast.success("Policy created successfully");
        let newMessage = `${policy.policyHeading} policy is added and is effective from ${policy.effectiveFrom}`
        newPolicy(newMessage)
        unSubNewPolicy()
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message);
      });
  };
  return (
    <Main>
      <Search>
        <AffiliateSection>
          <SubHeading>Policies | CML All</SubHeading>
          <button onClick={() => setpopUp(!popUp)}>
            <AddIcon /> Add Policy
          </button>
        </AffiliateSection>
        <SearchSection2>
          <InputSection>
            <h3>Title:</h3>
            <div>
              <select name="title" id="title">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <InputSection>
            <h3>Effective:</h3>
            <div>
              <select name="effective" id="effective">
                <option value="">select</option>
              </select>
            </div>
          </InputSection>
          <Button>Search</Button>
        </SearchSection2>
      </Search>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>Policy Title:</h3>
                    <input
                      type="text"
                      name="policyHeading"
                      id="policyHeading"
                      placeholder="type here..."
                      value={policy.policyHeading}
                      onChange={handleChange}
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Policy Description:</h3>
                    <textarea
                      name="policyDescription"
                      id="policyDescription"
                      value={policy.policyDescription}
                      onChange={handleChange}
                    ></textarea>
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button onClick={()=>{
                  setpopUp(false)
                  setpolicy({
                    policyHeading: "",
                    policyDescription: "",
                    effectiveFrom: "",
                  });
                }}>Cancel</button>
              </ButtonPart>
            </Section>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>Effective Form:</h3>
                    <div>
                      <input
                        type="date"
                        name="effectiveFrom"
                        id="effectiveFrom"
                        value={policy.effectiveFrom}
                        onChange={handleChange}
                      />
                    </div>
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const AffiliateSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 75%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  padding: 1em 2em;
  height: 60%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  button {
    padding: 1em 2em;
    min-width: 8em;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;
