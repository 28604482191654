import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetCompletedTaskQuery } from "../../../features/taskSlice";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../../Shared/colors";

const columns = [
  {
    field: "client",
    headerName: "Client",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.client}>
          <div>{params.row.client}</div>
        </Tooltip>
      );
    },
  },
  {
    field: "cellNo",
    headerName: "Cell No",
    width: 150,
    renderCell: (params) => {
      return <div>+{params.row.cellNo}</div>;
    },
  },
  // {
  //   field: "currentTaskType",
  //   headerName: "Task Type",
  //   width: 150,
  // },
  // {
  //   field: "currentSubTypeTask",
  //   headerName: "Task Sub Type",
  //   width: 150,
  // },
  {
    field: "completedDate",
    headerName: "Completed Date",
    width: 150,
    renderCell: (params) => {
      let date = params.row.completedDate;
      return <div>{date.toLocaleString()}</div>;
    },
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 150,
  },
  {
    field: "comment",
    headerName: "Comment",
    width: 150,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.comment}>
          <div>{params.row.comment}</div>
        </Tooltip>
      );
    },
  },
  {
    field: "attachment",
    headerName: "Attachment",
    width: 150,
    renderCell: (params) => {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.row.attachment ? (
            <div>
              <a href={params.row.attachment} target="_blank" style={{textDecoration:'none',color:`${colors.brown}`,fontWeight:'bolder',border:`1px solid ${colors.brown}`,padding:'0.5em',borderRadius:'5px'}}>
                click to view
              </a>
            </div>
          ) : (
            <div>no pic</div>
          )}
        </div>
      );
    },
  },
  {
    field: "empName",
    headerName: "Employee Name",
    width: 200,
  },
];

export const TaskTable = ({ leadId }) => {
  const { data: taskData, isFetching } = useGetCompletedTaskQuery(leadId);

  const [pageSize, setPageSize] = useState(5);

  console.log("this is task data", taskData);

  let rows = [];
  if (taskData) {
    let client = taskData[0]?.client.fullName;
    let cellNo = taskData[0]?.client?.cellNo[0]?.phoneNo;

    taskData[0]?.currentTask.forEach((task) => {
      rows.push({
        id: task._id,
        client: client,
        cellNo: cellNo,
        currentTaskType: task?.currentTaskType,
        currentSubTypeTask: task?.currentSubTypeTask,
        completedDate: new Date(task?.completedDate),
        reason: task?.reason,
        comment: task?.comment,
        attachment: task?.attachment,
        empName:task?.empName
      });
    });
  }
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      loading={isFetching}
      sx={{
        border: "none",
      }}
    />
  );
};
