import React, { useState, useMemo, useRef } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { colors } from "../../Shared/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import {
  useAssignStaffLeadsMutation,
  useBlockStaffMutation,
  useEditStaffMutation,
  useGetStaffQuery,
} from "../../features/employeeSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Toaster, toast } from "react-hot-toast";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useGetAllRegionQuery } from "../../features/regionSlice";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const StaffDataTable = () => {
  const [pageSize, setPageSize] = useState(10);
  const [popUp, setpopUp] = useState(false);
  const [blockPopUp, setblockPopUp] = useState(false);
  const [staffId, setstaffId] = useState(null);
  const [newEmployeeId, setnewEmployeeId] = useState("");

  const fileInputRef = useRef(null)

  const [assignLeads, setassignLeads] = useState(false);

  const { data: staff, isFetching } = useGetStaffQuery();
  const [editStaff, { isLoading }] = useEditStaffMutation();
  const [blockStaff, { isLoading: isBlockLoading }] = useBlockStaffMutation();
  const [assignStaffLeads, { isLoading: assignLeadsLoading }] =
    useAssignStaffLeadsMutation();

  let { data: region } = useGetAllRegionQuery();

  const [staffData, setstaffData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNum: null,
    cnic: null,
    address: "",
    gender: "",
    designation: "",
    profilePic: null,
    region: "",
    assignArea: "",
    role: "",
    empID: "",
  });
  const handleStaffChange = (e) => {
    const { name, value } = e.target;
    setstaffData((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  const handleProfilePicChange = (e) => {
    let file = e.target.files[0];
    setstaffData((prevstate) => ({
      ...prevstate,
      profilePic:file,
    }));

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const columns = [
    {
      field: "cnic",
      headerName: "Cnic",
      width: 150,
    },
    {
      field: "empId",
      headerName: "EMP. ID",
      width: 150,
    },
    {
      field: "empName",
      headerName: "EMP. NAME",
      width: 150,
    },
    {
      field: "desig",
      headerName: "DESIG",
      width: 150,
    },
    {
      field: "city",
      headerName: "CITY",
      width: 110,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 80,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <Action>
            <EditIcon
              style={{ color: `${colors.textBlue}`, cursor: "pointer" }}
              onClick={() => {
                setstaffId(params.row.id);
                setpopUp(!popUp);
              }}
            />
          </Action>
        );
      },
    },
    {
      field: "block",
      headerName: "Block",
      width: 80,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        return (
          <Action>
              <button
                onClick={() => {
                  setstaffId(params.row.id);
                  setblockPopUp(!blockPopUp);
                }}
                style={{
                  backgroundColor: params.row.isBlock
                    ? `${colors.hot}`
                    : `${colors.brown}`,
                  color: `${colors.white}`,
                  padding:'0.5em 1em',
                  border:'none',
                  borderRadius:'5px',
                  cursor:'pointer'
                }}
              >
                {params.row.isBlock ? <>Blocked</> : <>Block</>}
              </button>
          </Action>
        );
      },
    },
  ];
  const handleStaffSave = (e) => {
    e.preventDefault();
    editStaff({ staffId, staffData })
      .unwrap()
      .then((res) => {
        toast.success("Staff Updated Successfully");
        setstaffData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phoneNum: null,
          cnic: null,
          address: "",
          gender: "",
          designation: "",
          profilePic: null,
          region: "",
          assignArea: "",
          role: "",
          empID: "",
        });
      })
      .catch((err) => {
        toast.error("Error updating staff");
        console.log(err);
        setstaffData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phoneNum: null,
          cnic: null,
          address: "",
          gender: "",
          designation: "",
          profilePic: null,
          region: "",
          assignArea: "",
          role: "",
          empID: "",
        });
      });
  };

  const handleBlockStaff = (e) => {
    e.preventDefault();
    blockStaff({ staffId })
      .unwrap()
      .then((res) => {
        toast.success("Staff Blocked Successfully");
      })
      .catch((error) => {
        toast.error(error?.data?.error?.message);
      });
  };

  const handelAssignLeads = (e) => {
    e.preventDefault();
    let data = {
      newEmployeeId: newEmployeeId.id,
    };

    assignStaffLeads({ staffId, data })
      .unwrap()
      .then((res) => {
        toast.success("Leads assign successfully");
      })
      .catch((error) => {
        setblockPopUp(false);
        setnewEmployeeId("");
        setstaffId("");
        toast.error(error?.data?.error?.message);
      });
  };
  console.log(staff);
  const rows = [];
  staff?.forEach((staff) => {
    rows.push({
      id: staff._id,
      cnic: staff.cnic,
      empId: staff.empID,
      empName: staff.firstName + " " + staff.lastName,
      email: staff.email,
      phone: staff.phoneNum,
      desig: staff.designation,
      city: staff.address,
      block: staff.isBlock,
    });
  });

  const options = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );
    return options;
  }, [staff]);

  return (
    <Main>
      <LeadsSection>
        <div>
          Total Staff:<span> {staff?.length} </span>
        </div>
        <button>
          <PictureAsPdfIcon />
          Export
        </button>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isFetching}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img
              src="/assets/icons/cross.png"
              alt="cross"
              style={{ height: "50%" }}
            />
          </Cross>
          <PopUpSection>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>First Name:</h3>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={staffData.firstName}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Last Name:</h3>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={staffData.lastName}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Email:</h3>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={staffData.email}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Password:</h3>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={staffData.password}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Phone Num:</h3>
                    <input
                      type="tel"
                      name="phoneNum"
                      id="phoneNum"
                      value={staffData.phoneNum}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Cnic:</h3>
                    <input
                      type="tel"
                      name="cnic"
                      id="cnic"
                      value={staffData.cnic}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Address:</h3>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={staffData.address}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    setpopUp(false);
                    setstaffData({
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                      phoneNum: null,
                      cnic: null,
                      address: "",
                      gender: "",
                      designation: "",
                      profilePic: null,
                      region: "",
                      assignArea: "",
                      role: "",
                      empID: "",
                    });
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>EmpId:</h3>
                    <input
                      type="text"
                      name="empID"
                      value={staffData.empID}
                      onChange={handleStaffChange}
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Gender:</h3>
                    <div>
                      <select
                        name="gender"
                        id="gender"
                        value={staffData.gender}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Designation:</h3>
                    <div>
                      <select
                        name="designation"
                        id="designation"
                        value={staffData.designation}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="ceo">Ceo</option>
                        <option value="dos">Dos</option>
                        <option value="doa">Doa</option>
                        <option value="account">Account</option>
                        <option value="dm">Dm</option>
                        <option value="rsm">Rsm</option>
                        <option value="sm">Sm</option>
                        <option value="manger">Manager</option>
                        <option value="bda">Bda</option>
                        <option value="am">Am</option>
                        <option value="developer">Developer</option>
                        <option value="v3">V3</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Region:</h3>
                    <div>
                      <select
                        name="region"
                        id="region"
                        value={staffData.region}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        {region?.map((region) => (
                          <option key={region._id} value={region._id}>
                            {region.regionName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Assign Area:</h3>
                    <input
                      type="text"
                      name="assignArea"
                      id="assignArea"
                      value={staffData.assignArea}
                      onChange={handleStaffChange}
                      required
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Role:</h3>
                    <div>
                      <select
                        name="role"
                        id="role"
                        value={staffData.role}
                        onChange={handleStaffChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="ceo">Ceo</option>
                        <option value="dos">Dos</option>
                        <option value="doa">Doa</option>
                        <option value="account">Account</option>
                        <option value="dm">Dm</option>
                        <option value="rsm">Rsm</option>
                        <option value="sm">Sm</option>
                        <option value="manger">Manager</option>
                        <option value="bda">Bda</option>
                        <option value="am">Am</option>
                        <option value="developer">Developer</option>
                        <option value="v3">V3</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Attachment:</h3>
                    <span>
                      <p>upload file here</p>
                      <label htmlFor="Pic">
                        <AttachFileIcon /> Upload
                      </label>
                      {staffData.profilePic && (
                        <UploadedFile
                          src={URL.createObjectURL(staffData.profilePic)}
                          alt="uploaded file"
                        />
                      )}
                    </span>
                    <input
                      type="file"
                      name="Pic"
                      id="Pic"
                      onChange={handleProfilePicChange}
                      ref={fileInputRef}
                      required
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity:
                      isLoading ||
                      (!staffData.firstName &&
                        !staffData.lastName &&
                        !staffData.email &&
                        !staffData.password &&
                        staffData.phoneNum === null &&
                        staffData.cnic === null &&
                        !staffData.address &&
                        !staffData.gender &&
                        !staffData.designation &&
                        staffData.profilePic === null &&
                        !staffData.region &&
                        !staffData.assignArea &&
                        !staffData.role &&
                        !staffData.empID)
                        ? "0.5"
                        : "1",
                  }}
                  type="submit"
                  disabled={
                    isLoading ||
                    (!staffData.firstName &&
                      !staffData.lastName &&
                      !staffData.email &&
                      !staffData.password &&
                      staffData.phoneNum === null &&
                      staffData.cnic === null &&
                      !staffData.address &&
                      !staffData.gender &&
                      !staffData.designation &&
                      staffData.profilePic === null &&
                      !staffData.region &&
                      !staffData.assignArea &&
                      !staffData.role &&
                      !staffData.empID)
                  }
                  onClick={handleStaffSave}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={blockPopUp}>
        <PopUp style={{ height: "50%" }}>
          <Cross onClick={() => setblockPopUp(!blockPopUp)}>
            <img
              src="/assets/icons/cross.png"
              alt="cross"
              style={{ height: "50%" }}
            />
          </Cross>
          <PopUpSection>
            <BlockPopUpSection>
              <BlockSection>
                <h3>Do You Want To Block This Staff Member?</h3>
                <BlockButtons>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setblockPopUp(false);
                      setnewEmployeeId("");
                      setstaffId("");
                    }}
                  >
                    No
                  </button>
                  <button
                    style={{
                      backgroundColor: `${colors.hot}`,
                      color: `${colors.white}`,
                    }}
                    onClick={handleBlockStaff}
                    disabled={isBlockLoading}
                  >
                    {isBlockLoading ? <>Blocking...</> : <>BLock</>}
                  </button>
                </BlockButtons>
              </BlockSection>
              <CheckSection>
                <h4>Do you want to assign leads to other staff member</h4>
                <input
                  type="checkbox"
                  name="block"
                  onChange={(e) => {
                    setassignLeads(!assignLeads);
                  }}
                />
              </CheckSection>
              <BlockSection>
                {assignLeads ? (
                  <>
                    <AutocompleteSection>
                      <AutoCompleteInput>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={options}
                          // value={kpiData.staff}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          onChange={(event, newvalue) => {
                            console.log(newvalue);
                            // setkpiData({ ...kpiData, staff: newvalue })
                            setnewEmployeeId(newvalue);
                          }}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            } else if (
                              typeof option === "object" &&
                              option !== null &&
                              option.hasOwnProperty("label")
                            ) {
                              return option.label;
                            } else if (
                              typeof option === "object" &&
                              option !== null &&
                              option.hasOwnProperty("id")
                            ) {
                              return option.id;
                            } else {
                              return "";
                            }
                          }}
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            height: "2.9em",
                            overflow: "hidden",
                            border: `1px solid ${colors.inputBorderWhite}`,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                backgroundColor: `${colors.white}`,
                                height: "2.9em",
                              }}
                            />
                          )}
                        />
                      </AutoCompleteInput>
                    </AutocompleteSection>
                    <BlockButtons>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setblockPopUp(false);
                          setnewEmployeeId("");
                          setstaffId("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          backgroundColor: `${colors.cold}`,
                          color: `${colors.white}`,
                        }}
                        disabled={assignLeadsLoading}
                        onClick={handelAssignLeads}
                      >
                        {assignLeadsLoading ? <>Assigning...</> : <>Assign</>}
                      </button>
                    </BlockButtons>
                  </>
                ) : null}
              </BlockSection>
            </BlockPopUpSection>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
`;
const TimeSection = styled.div`
  height: 10%;
  border-bottom: 1px solid ${colors.inputBorderWhite};
  display: flex;
  align-items: flex-end;
  button {
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover {
      background-color: ${colors.brown};
      color: ${colors.white};
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.textMed};
    padding: 1em;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    cursor: pointer;
    font-size: clamp(0.8em, 0.9vw, 1em);
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  overflow: scroll;
`;

const Cross = styled.div`
  width: 100%;
  height: 5%;
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.form`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 50%;
  min-height: 60em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Task = styled.div`
  padding: 1em 2em;
  height: 100%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 30%;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const BlockPopUpSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
`;

const BlockButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  button {
    min-width: 8em;
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const BlockSection = styled.div`
  /* min-height: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
`;

const CheckSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    margin-left: 1em;
    margin-top: 5px;
  }
`;

const AutocompleteSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AutoCompleteInput = styled.div`
  width: 65%;
`;
