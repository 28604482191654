import { apiSlice } from "./api/apiSlice";

const affiliateSlice = apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        createAffiliateAgency:builder.mutation({
            query:(affiliate)=>({
                url:'affiliates/create/agency',
                method:'POST',
                body:affiliate
            }),
            invalidatesTags:['Affiliate']
        }),

        verifyAffiliateAgent:builder.mutation({
            query:(id)=>({
                url:`affiliates/verify/${id}`,
                method:'PUT',
            }),
            invalidatesTags:['Affiliate']
        }),

        deleteAffiliate:builder.mutation({
            query:(id)=>({
                url:`affiliates/${id}`,
                method:'DELETE'
            }),
            invalidatesTags:['Affiliate']
        }),

        getAllAffiliate:builder.query({
            query:(affType)=>({
                url:`/affiliates?affType=${encodeURIComponent(affType)}`,
            }),
            transformResponse:(response,meta,arg)=>response.affiliates,
            providesTags:['Affiliate']
        }),

        getSingleAffiliate:builder.query({
            query:(id)=>({
                url:`affiliates/${id}`,
            }),
            providesTags:['Affiliate']
        }),

        getApprovedAffiliate:builder.query({
            query:()=>({
                url:'/affiliates/approved/affiliates'
            }),
            transformResponse:(response,meta,arg)=>response.affiliate,
            providesTags:['Affiliate']
        }),
        getAffiliateLeads:builder.query({
            query:(id)=>({
                url:`affiliates/leads/${id}`
            }),
            transformResponse:(response,meta,arg)=>response.leads,
            providesTags:['Leads']
        }),
        addAffiliateComment:builder.mutation({
            query:({id,body})=>({
                url:`affiliates/comments/${id}`,
                method:'POST',
                body
            }),
            invalidatesTags:['Affiliate']
        }),
    })
})

export const {useLazyGetAffiliateLeadsQuery, useAddAffiliateCommentMutation,useCreateAffiliateAgencyMutation, useVerifyAffiliateAgentMutation, useDeleteAffiliateMutation, useLazyGetAllAffiliateQuery, useGetSingleAffiliateQuery ,useGetApprovedAffiliateQuery} = affiliateSlice;