import React,{useState} from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import { colors } from '../../Shared/colors';
import EditIcon from '@mui/icons-material/Edit';



export const ReportsDatatable = () => {
  const [pageSize, setPageSize] = useState(5);

  const columns = [
    {
        field: 'type',
        headerName: 'Type',
        width: 150,
    },
    {
        field: 'tcDate',
        headerName: 'TC Date',
        width: 150,
    }
    ,
    {
      field:'taskBy',
      headerName:'Task By',
      width:110,
    }
    ,
    {
      field:'client',
      headerName:'Client',
      width:110,
    }
    ,
    {
      field:'leadId',
      headerName:'Lead Id',
      width:110,
    }
    ,
    {
      field:'source',
      headerName:'Source',
      width:110,
    },
    {
      field:'interest',
      headerName:'Interest',
      width:110,
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 80,
      sortable: false ,
      filterable:false,
      hideable:false,
      renderCell:()=>{
        return(
          <Action>
            <EditIcon style={{color:`${colors.textBlue}`,cursor:'pointer'}}/>
          </Action>
        )
      }
    },
  ];


const rows = [
  
];

  return (
    <Main>
      <LeadsSection>
        <div>

      Total Reports:<span> 236 </span> 
        </div>
      </LeadsSection>
      <Table>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          border:'none'
        }}
      />
      </Table>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 3em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`

const LeadsSection = styled.p`
font-size: clamp(1.5em,1.5vh,2em);
padding: 0.5em 0em;
height: 10%;
width: 100%;
display: flex;
justify-content: space-between;
span{
  font-weight: bold;
}
`

const Table = styled.div`
height: 80%;
`




const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`