import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Puff } from "react-loader-spinner";
import {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
} from "../../features/projectSlice";
import { useCreateInventoryMutation, useGetAllInventoryQuery } from "../../features/InventorySlice";
import { toast, Toaster } from "react-hot-toast";
import Papa from "papaparse";
import { InventoryDataTable } from "./InventoryDataTable";

export const InventoryFirst = () => {
  const [popUp, setpopUp] = useState(false);
  const [areaType, setareaType] = useState("");
  const [loading, setloading] = useState(false);
  // const [inventoryData, setinventoryData] = useState([])
  const [invenQuery, setinvenQuery] = useState({
    project: "",
    unitType: "",
    projectBlock: "",
    unitNo: "",
    status: "",
  });

  const [invenValues, setinvenValues] = useState({
    project: "",
    unitType: "",
    projectBlock: "",
    unitNo: "",
    status: "",
  });

  const {
    data:InventoryData,
    refetch: refetchInventory,
  } = useGetAllInventoryQuery({project:invenQuery.project, unitType:invenQuery.unitType, projectBlock:invenQuery.projectBlock, unitNo:invenQuery.unitNo, status:invenQuery.status})

  const role = localStorage.getItem("role");

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);


  let handleInveQueryChange = (e) => {
    let { name, value } = e.target;
    setinvenValues({
      ...invenValues,
      [name]: value,
    });
  };

  let handleGetInvenQuery = () =>{
    setinvenQuery(invenValues)
    refetchInventory()
    .then((res)=>{
      toast.success("inventory loaded successfully!")
    })
    .catch((err)=>{
      toast.error("Error while loading inventory!")
    })
  }

  const [inventory, setinventory] = useState({
    projectId: "",
    unitNo: "",
    invenType: "",
    bed: "",
    marlaPrice: null,
    totalPrice: null,
    areaInFet: "",
    areaInMarla: "",
    plotSize: "",
    location: "",
    crossArea: "",
    features: "",
    projectBlockName: "",
    sqftPrice: null,
    status: "",
  });

  let { data: project } = useGetAllProjectsQuery();

  const handleProject = (e) => {
    let { name, value } = e.target;
    setloading(true);
    setinventory({ ...inventory, [name]: value });
  };
  let { data: singleProject } = useGetSingleProjectQuery(inventory.projectId);

  useEffect(() => {
    if (singleProject) {
      setloading(false);
    }
  }, [singleProject]);

  let handleArea = (e) => {
    if (e.target.value === "areaInFeet") {
      setareaType(e.target.value);
    } else {
      setareaType(e.target.value);
    }
  };

  let handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.name === "totalPrice" || e.target.name === "marlaPrice") {
      setinventory({ ...inventory, [name]: parseInt(value) });
    } else {
      setinventory({ ...inventory, [name]: value });
    }
  };

  let [addInventory, { isLoading }] = useCreateInventoryMutation();

  const handleInventorySubmit = (e) => {
    e.preventDefault();

    addInventory({
      projectID: inventory.projectId,
      unitNo: inventory.unitNo,
      invenType: inventory.invenType,
      bed: inventory.bed,
      marlaPrice: inventory.marlaPrice,
      totalPrice: inventory.totalPrice,
      areaInFet: inventory.areaInFet,
      areaInMarla: inventory.areaInMarla,
      plotSize: inventory.plotSize,
      location: inventory.location,
      crossArea: inventory.crossArea,
      features: inventory.features,
      projectBlockName: inventory.projectBlockName,
      sqftPrice: inventory.sqftPrice,
      status: inventory.status,
    })
      .unwrap()
      .then(() => {
        toast.success("Inventory created successfully");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      });
    setinventory({
      projectId: "",
      unitNo: "",
      invenType: "",
      bed: "",
      marlaPrice: null,
      totalPrice: null,
      areaInFet: "",
      areaInMarla: "",
      plotSize: "",
      location: "",
      crossArea: "",
      features: "",
      projectBlockName: "",
      sqftPrice: null,
      status: "",
    });
    setareaType("");
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    let bulk = [];

    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          results.data.map((site) =>
            bulk.push({
              unitNo: site[0],
              invenType: site[1],
              bed: site[2],
              marlaPrice: site[3],
              totalPrice: site[4],
              areaInFet: site[5],
              areaInMarla: site[6],
              plotSize: site[7],
              location: site[8],
              crossArea: site[9],
              features: site[10],
              sqftPrice: site[11],
              status: site[12],
              projectID: inventory.projectId,
              projectBlockName: inventory.projectBlockName,
            })
          );
          console.log(bulk);
          for (let i = 0; i < bulk.length; i++) {
            console.log(bulk[i]);
            addInventory(bulk[i])
              .unwrap()
              .then(() => {
                toast.success("inventory added successfully");
              })
              .catch((err) => {
                toast.error(err.data.error.message);
              });
          }
        },
      });
    }
    e.target.value = "";
  };

  return (
    <>
    <Main>
      <Search>
        <AffiliateSection>
          <SubHeading>Search</SubHeading>
          {role === "hr" ||
          role === "v3" ||
          role === "developer" ||
          role === "director" ||
          role === "ceo" ? (
            <button onClick={() => setpopUp(!popUp)}>
              <AddIcon /> Add Inventory
            </button>
          ) : null}
        </AffiliateSection>
        {/* project: "",
    unitType: "",
    floorBlock: "",
    unitNo: "",
    status: "", */}
        <SearchSection1>
          <InputSection>
          <h3>Project:</h3>
              <div>
                <select
                  name="project"
                  id="project"
                  value={invenValues.project}
                  onChange={handleInveQueryChange}
                >
                  <option value="">select</option>
                  {project?.map((proj) => (
                    <option value={proj._id}>{proj.projectName}</option>
                  ))}
                </select>
              </div>
          </InputSection>

          <InputSection>
            <h3>Project Block:</h3>
              <input type="text" name="projectBlock" id="projectBlock" value={invenValues.projectBlock} onChange={handleInveQueryChange}/>

          </InputSection>
          {/* <InputSection>
            <h3>Unit Location:</h3>
            <div>
              <select name="unitLocation" id="unitLocation">
                <option value="">Select</option>
              </select>
            </div>
          </InputSection> */}
          <InputSection>
            <h3>Unit Type:</h3>
            <div>
              <select name="unitType" id="unitType" value={invenValues.unitType} onChange={handleInveQueryChange}>
                <option value="">select</option>
                <option value="apartments">apartments</option>
                <option value="house">house</option>
                <option value="plot">plot</option>
              </select>
            </div>
          </InputSection>
        </SearchSection1>
        <SearchSection2>
          <InputSection>
            <h3>Unit No:</h3>
              <input type="text" name="unitNo" id="unitNo" value={invenValues.unitNo} onChange={handleInveQueryChange}/>
          </InputSection>
          <InputSection>
            <h3>Status:</h3>
            <div>
              <select name="status" id="status" value={invenValues.status} onChange={handleInveQueryChange}>
                <option value="">select</option>
                <option value="Available">Available</option>
              </select>
            </div>
          </InputSection>
          <InputSection></InputSection>
          <Button
          onClick={handleGetInvenQuery}
          >Search</Button>
        </SearchSection2>
      </Search>
      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross onClick={() => setpopUp(!popUp)}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection onSubmit={handleInventorySubmit}>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>Unit No:</h3>
                    <input
                      type="text"
                      name="unitNo"
                      id="unitNo"
                      value={inventory.unitNo}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Inventory Type:</h3>
                    <div>
                      <select
                        name="invenType"
                        id="invenType"
                        onChange={handleChange}
                      >
                        <option value="">select</option>
                        <option value="apartments">Apartments</option>
                        <option value="house">House</option>
                        <option value="plot">Plot</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Bed:</h3>
                    <input
                      type="text"
                      name="bed"
                      id="bed"
                      value={inventory.bed}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Area Type:</h3>
                    <div>
                      <select
                        name="areaType"
                        id="areaType"
                        onChange={handleArea}
                      >
                        <option value="">select</option>
                        <option value="areaInFeet">Area In Feet</option>
                        <option value="areaInMarla">Area In Marla</option>
                      </select>
                    </div>
                  </InputFeild>
                  {areaType === "areaInFeet" ? (
                    <InputFeild>
                      <h3>Area In Feet:</h3>
                      <input
                        type="text"
                        name="areaInFet"
                        id="areaInFet"
                        value={inventory.areaInFet}
                        onChange={handleChange}
                        placeholder="type here..."
                      />
                    </InputFeild>
                  ) : null}
                  {areaType === "areaInMarla" ? (
                    <InputFeild>
                      <h3>Area in Marla:</h3>
                      <input
                        type="text"
                        name="areaInMarla"
                        id="areaInMarla"
                        value={inventory.areaInMarla}
                        onChange={handleChange}
                        placeholder="type here..."
                      />
                    </InputFeild>
                  ) : null}
                  <InputFeild>
                    <h3>Cross Area:</h3>
                    <input
                      type="text"
                      name="crossArea"
                      id="crossArea"
                      value={inventory.crossArea}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Project:</h3>
                    <div>
                      <select
                        name="projectId"
                        id="projectId"
                        onChange={handleProject}
                      >
                        <option value="">Select Project</option>
                        {project?.map((pro) => (
                          <option value={pro._id}>{pro.projectName}</option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  {loading ? (
                    <Loader>
                      <Puff
                        height="40"
                        width="40"
                        radius={1}
                        color={colors.brown}
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </Loader>
                  ) : null}
                  {singleProject?.projectBlock?.length > 0 ? (
                    <InputFeild>
                      <h3>Project Block:</h3>
                      <div>
                        <select
                          name="projectBlockName"
                          id="projectBlockName"
                          value={inventory.projectBlockName}
                          onChange={handleChange}
                        >
                          <option value="">select</option>
                          {singleProject?.projectBlock?.map((pro) => (
                            <option value={pro.blockName}>
                              {pro.blockName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </InputFeild>
                  ) : null}
                  <InputFeild>
                    <h3>Features:</h3>
                    <input
                      type="text"
                      name="features"
                      id="features"
                      value={inventory.features}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Plot Size:</h3>
                    <input
                      type="text"
                      name="plotSize"
                      id="plotSize"
                      value={inventory.plotSize}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Total Price:</h3>
                    <input
                      type="text"
                      name="totalPrice"
                      id="totalPrice"
                      value={inventory.totalPrice}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Marla Price:</h3>
                    <input
                      type="text"
                      name="marlaPrice"
                      id="marlaPrice"
                      value={inventory.marlaPrice}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Square Feet Price:</h3>
                    <input
                      type="text"
                      name="sqftPrice"
                      id="sqftPrice"
                      value={inventory.sqftPrice}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Status:</h3>
                    <input
                      type="text"
                      name="status"
                      id="status"
                      value={inventory.status}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                  <InputFeild>
                    <h3>Location:</h3>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      value={inventory.location}
                      onChange={handleChange}
                      placeholder="type here..."
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    setpopUp(false);
                    setinventory({
                      projectId: "",
                      unitNo: "",
                      invenType: "",
                      bed: "",
                      marlaPrice: null,
                      totalPrice: null,
                      areaInFet: "",
                      areaInMarla: "",
                      plotSize: "",
                      location: "",
                      crossArea: "",
                      features: "",
                      projectBlockName: "",
                    });
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task>
                <InputPart>
                  <InputFeild>
                    <h3>Project:</h3>
                    <div>
                      <select
                        name="projectId"
                        id="projectId"
                        onChange={handleProject}
                      >
                        <option value="">Select Project</option>
                        {project?.map((pro) => (
                          <option value={pro._id}>{pro.projectName}</option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  {loading ? (
                    <Loader>
                      <Puff
                        height="40"
                        width="40"
                        radius={1}
                        color={colors.brown}
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </Loader>
                  ) : null}
                  {singleProject?.projectBlock?.length > 0 ? (
                    <InputFeild>
                      <h3>Project Block:</h3>
                      <div>
                        <select
                          name="projectBlockName"
                          id="projectBlockName"
                          value={inventory.projectBlockName}
                          onChange={handleChange}
                        >
                          <option value="">select</option>
                          {singleProject?.projectBlock?.map((pro) => (
                            <option value={pro.blockName}>
                              {pro.blockName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </InputFeild>
                  ) : null}

                  <InputFeild>
                    <h3>Attachment:</h3>
                    <span>
                      <p>upload file here</p>
                      <label htmlFor="profilePic">
                        <AttachFileIcon /> Upload
                      </label>
                    </span>
                    <input
                      type="file"
                      name="profilePic"
                      id="profilePic"
                      onChange={(e) => handleFileUpload(e)}
                    />
                  </InputFeild>
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleInventorySubmit}
                  disabled={isLoading}
                >
                  {isLoading ? <>saving...</> : <>save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <Toaster />
    </Main>
      <InventoryDataTable inventoryData={InventoryData}/>
    </>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const AffiliateSection = styled.div`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${colors.textBlue};
    color: ${colors.white};
    border: none;
    border-radius: 5px;
  }
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  font-size: clamp(1.2em, 1.2vw, 1.5em);
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InputSection = styled.div`
  min-width: 2em;
  width: 20vw;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.white};
    select {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0vh;
  left: 0;
  z-index: 11;
  position: fixed;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 50%;
  height: 90%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  overflow: scroll;
`;

const Cross = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  img {
    height: 20%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.form`
  height: calc(100% - 10%);
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Section = styled.div`
  width: 50%;
  min-height: 100em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Task = styled.div`
  padding: 1em 2em;
  height: 100%;
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  textarea {
    min-height: 11em;
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const Loader = styled.div`
  margin-top: 1em;
`;
