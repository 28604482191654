import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { subscribeToNotifications } from "../../../services/socket";
import { useDeleteAllMutation, useGetAllNotificationsQuery, useReadAllMutation } from "../../../features/notificationSlice";
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment';
import { useNotification } from "../../../context/notficationContext";

const Notifications = () => {

  let { data: notificationsData,refetch } = useGetAllNotificationsQuery();
  const [deleteAll,{isLoading:deleteLoading}] = useDeleteAllMutation()
  const [readAll,{isLoading:readLoading}] = useReadAllMutation()
  const [Notification, setNotification] = useState('')

  const {isNotification,toggleNotificationFalse} = useNotification()
  
  useEffect(() => {
    refetch()
    setNotification("")
  }, [Notification])

  useEffect(() => {
    subscribeToNotifications((data) => {
      setNotification(data?.newMessage);
    });
  }, []);

  useEffect(() => {
    if(isNotification){
      toggleNotificationFalse()
    }
  }, [isNotification])
  
  const markAllAsRead = () => {
    readAll().unwrap()
    .then((res)=>{
      toast.success("Marked all as read!")
    })
    .catch((err)=>{
      console.log(err)
      toast.error("unable to mark all notifications as read!")
    })
  };

  const deleteAllNotifications = () => {
    deleteAll().unwrap()
    .then((res)=>{
      toast.success("Notifications deleted successfully!")
    })
    .catch((err)=>{
      console.log(err)
      toast.error("Error while deleting notifications!")
    })
  };
  console.log("these are notifications", notificationsData);
  return (
    <Main>
      <Section>
        <Head>
          <h1>Notifications</h1>
          <div>
            <button onClick={markAllAsRead}> 
              {
                readLoading?
                'processing...':'Mark All as Read'
              }
              </button>
            <button onClick={deleteAllNotifications}>
              {
                deleteLoading?
                'processing...':
                'Delete All'
              }
            </button>
          </div>
        </Head>
        <Body>
            {
              notificationsData && notificationsData.length>0 ? (
                notificationsData?.map((ls,index)=>(
                  <NotificationComponent key={index} read={ls?.isRead}>
                    <div>
                    {ls?.message}
                    </div>
                    <div>
                    {
                      moment(new Date(ls?.createdAt)).fromNow(true)
                    } ago
                    </div>
                  </NotificationComponent>
                ))
              ):(
                <h4>No New Notfications!</h4>
              )
            }
        </Body>
      </Section>
      <Toaster/>
    </Main>
  );
};

export default Notifications;

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Section = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  overflow-y: scroll;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  div {
    display: flex;
    justify-content: space-between;
    min-width: 20em;
    button {
      min-width: 10em;
      padding: 1em 2em;
      border-radius: 5px;
      border: none;
      background-color: ${colors.brown};
      color: ${colors.white};
      cursor: pointer;
      outline: none;
    }
  }
`;

const Body = styled.div`
height: 90%;
background-color: ${colors.white};
border-radius: 5px;
padding: 1em;
overflow-y: scroll;
display: flex;
flex-direction: column;
h4{
  color: ${colors.brown};
}

`

const NotificationComponent = styled.div`
  margin: 0.3em 0;
  background-color: ${(props)=> (props.read?`${colors.inputWhite}`:`${colors.brown}`)};
  color:${(props)=> (props.read?`${colors.black}`:`${colors.white}`)};
  border: 1px solid ${colors.brown};
  padding: 1em;
  border-radius: 5px;
  cursor: pointer;
  display:flex;
  justify-content:space-between;
`