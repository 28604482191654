import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'

export const ClientsFirst = () => {
  return (
    <Main>
      <Search>
        <Heading>
            Search Client
        </Heading>
        <SearchSection1>
        <InputSection>
          <h3>Client Id:</h3>
          <input type="text" name="clientId" id="clientId" placeholder='Type ID'/>
        </InputSection>
        <InputSection>
          <h3>Cell No:</h3>
          <input type="tel" name="cellNumber" id="cellNumber" placeholder='Cell No'/>
        </InputSection>
        <InputSection>
          <h3>Allocation:</h3>
          <input type="text" name="allocation" id="allocation" placeholder='Choose Staff'/>
        </InputSection>
        </SearchSection1>
        <SearchSection2>
        <InputSection>
          <h3>Project:</h3>
          <div>
          <select name="project" id="project">
            <option value="" disabled selected>Select Project</option>
          </select>
          </div>
        </InputSection>
        <InputSection>
          <h3>Inactive:</h3>
          <div>
          <select name="inactive" id="inactive">
            <option value="" disabled selected>any</option>
          </select>
          </div>
        </InputSection>
        <Button>
            Search
        </Button>
        </SearchSection2>
      </Search>
      <Favourite>
        <Heading>
            Favourite Clients
        </Heading>
        <FavouriteSection>
          <FavouriteHeading>
            <p>Client</p>
            <p>Id</p>
          </FavouriteHeading>
          <FavouriteData>
            <div>
              <p>Furqan</p>
              <p>1 lead</p>
            </div>
            <div>
              <p>123456</p>
              <p>October 26,2022</p>
            </div>
          </FavouriteData>
          <FavouriteData>
            <div>
              <p>Furqan</p>
              <p>1 lead</p>
            </div>
            <div>
              <p>123456</p>
              <p>October 26,2022</p>
            </div>
          </FavouriteData>
        </FavouriteSection>
        <Button>
          View All
        </Button>
      </Favourite>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
min-height: 30vh;
`

const Search = styled.div`
background-color: ${colors.inputWhite};
width: 65%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 1em;
`

const Heading = styled.h4`
height: 30%;
width: 100%;
color: ${colors.text};
font-size: clamp(1em,1.5vw,2em);
border-bottom: 1px solid ${colors.inputBorderWhite};
padding-bottom: 1em;
`

const SearchSection1 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
`

const SearchSection2 = styled.div`
height: 30%;
display: flex;
justify-content: space-between;
align-items: flex-end;
`

const Favourite = styled.div`
background-color: ${colors.inputWhite};
width: 30%;
box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
border-radius: 5px;
min-height: 19em;
padding: 1em;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
`

const FavouriteSection = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
width: 100%;
`

const FavouriteHeading = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
p{
  padding: 1em 0em;
  width: 40%;
  font-size: clamp(1em,1vh,2em);
}
`

const FavouriteData = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
div{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 3em;
  p{
    font-size: clamp(1em,1vh,2em);
    :nth-child(1){
      color: ${colors.textBlue};
    }
  }
}
`

const InputSection = styled.div`
min-width: 2em;
width: 12vw;
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.white};
  select{
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
}
input{
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  background-color: ${colors.white};
  width: 100%;
  padding: 1em 0em 1em 1em;
  outline: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
`

const Button = styled.button`
padding: 1em 2em;
color: ${colors.white};
background-color: ${colors.brown};
border: none;
border-radius: 5px;
cursor: pointer;
`