import React, { useState ,useMemo} from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { toast, Toaster } from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../../Shared/colors";
import { Loader } from "../../../Shared/Loader";
import { useDeleteSingleStaffMutation, useGetHierarchyQuery } from "../../../features/employeeSlice";
import EditIcon from '@mui/icons-material/Edit';

const HierarchyEditTable = () => {
  const [pageSize, setPageSize] = useState(10);

  const {data:mainHierarchy} = useGetHierarchyQuery();

  const [deleteStaff,{isLoading}] = useDeleteSingleStaffMutation();

  const [staffHierarchy, setstaffHierarchy] = useState([])

  const columns = [
    {
      field: "profilePic",
      headerName: "Profile Pic",
      width: 200,
      renderCell: (params) => {
        return params.row.profilePic ? (
          <Img>
            <img src={`${params.row.profilePic}`} alt="avatar" />
          </Img>
        ) : (
          <Img>No pic</Img>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
    },
    {
      field: "empId",
      headerName: "Emp ID",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 110,
      renderCell: (params) => {
        return (
          <Action>
            <DeleteIcon
              style={{ color: `${colors.veryHot}`, cursor: "pointer" }}
              onClick={() => {handleDelete(params.row.id)}}
            />
          </Action>
        );
      },
    },
  ];

  const handleDelete = (staffId) =>{
    let parentId=staffHierarchy.employee._id;
    deleteStaff({parentId,staffId}).unwrap()
    .then((res)=>{
        toast.success("Staff deleted successfully")
    })
    .catch((err)=>{
        console.log("this is error ",err);
        toast.error("Error deleting staff")
    })
  }

  const handleEdit = (staffId) =>{
    console.log(staffId)
  }
  const rows = useMemo(() => {

    let options = [];
    staffHierarchy?.subordinates?.forEach(staff => {
        options.push({
            id:staff._id,
            profilePic:staff?.profilePic,
            name: staff?.firstName+' '+staff?.lastName,
            designation:staff?.designation,
            empId:staff?.empID,
        })
    });
    return options
  }, [staffHierarchy])

  return (
    <Main>
      <LeadsSection>
        <div>
          Hierarchy : <span>{rows?.length} </span>
        </div>
        <select name="subordinates" id="subordinates" onChange={(e)=>{
            setstaffHierarchy(JSON.parse(e.target.value))
            }}>
        <option>Select</option>
          {
            mainHierarchy?.map((staff)=>(
                <option value={JSON.stringify(staff)}>{staff?.employee?.firstName+' '+staff?.employee?.lastName}</option>
            ))
          }
        </select>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isLoading}
          sx={{
            border: "none",
          }}
        />
      </Table>
      <Toaster />
    </Main>
  );
};

export default HierarchyEditTable;

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
  position: relative;
`;

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  select {
    outline: none;
    min-width: 10em;
    padding: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
  }
`;

const Table = styled.div`
  height: 80%;
`;

const Img = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10em;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const Verified = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    width: 60%;
    color: white;
    padding: 0.5em 0.2em;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  cursor: pointer;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;
