import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Shared/colors'
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LayersIcon from '@mui/icons-material/Layers';
import { useGetTokensQuery } from '../../features/accountSlice';
import { useGetMeetingsQuery } from '../../features/employeeSlice';

export const DashboardFirst = () => {


  const {data:tokens} = useGetTokensQuery()

  const {data:meetings} = useGetMeetingsQuery()

  return (
    <Main>
      <InputPart>
        <InputSection>
          <h3>Date Added:</h3>
          <div>
          <select name="date" id="date">
            <option value="">Select Date</option>
          </select>
          </div>
        </InputSection>
        <InputSection>
          <h3>Staff:</h3>
          <div>
          <select name="date" id="date">
            <option value="">Choose Staff</option>
          </select>
          <SearchIcon sx={
            {
              position: 'absolute',
              top: '50%',
              right: '1%',
              transform: 'translateY(-50%)',
              color: `${colors.inputFeild}`
            }
          }/>
          </div>
        </InputSection>
      </InputPart>
      <CardPart>
        <CardSection>
            <CardIcon>
                <CalendarMonthIcon/>
            </CardIcon>
            <h3>
              Meetings
            </h3>
            <h2>
              {meetings?.countMeeting}
            </h2>
        </CardSection>
        <CardSection>
            <CardIcon>
                <LayersIcon/>
            </CardIcon>
            <h3>
              Token
            </h3>
            <h2>
              {tokens?.length}
            </h2>
        </CardSection>
      </CardPart>
    </Main>
  )
}


const Main = styled.div`
background-color: ${colors.white};
width: 90%;
display: flex;
justify-content: space-around;
align-items: flex-end;
margin-top: 3em;
`

const InputPart = styled.div`
width: 40%;
`

const InputSection = styled.div`
h3{
  color: ${colors.black};
  font-size: clamp(1em,1vw,2em);
  margin: 1em 0em;
}
div{
  position: relative;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 6px;
  width: 100%;
  padding-right: 1em;
  background-color: ${colors.inputWhite};
  select{
  background-color: ${colors.inputWhite};
  width: 100%;
  padding: 1em 0em 1em 2em;
  outline: none;
  border: none;
  ::placeholder{
    color: ${colors.inputFeild};
  }
}
/* .css-i4bv87-MuiSvgIcon-root */
}
:nth-child(2){
  div{
    padding-right: 2.5em;
  }
}
`

const CardPart = styled.div`
width: 40%;
display: flex;
justify-content: space-around;
align-items: center;
`

const CardSection = styled.div`
width: 45%;
background-color: ${colors.inputWhite};
border-radius: 5px;
border: 1px solid ${colors.inputBorderWhite};
min-height: 10em;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
h3{
  color: ${colors.brown};
  font-size: clamp(1em,3vh,2em);
}
h4{
  font-size: clmap(1em,3vh,2em);
}
`

const CardIcon = styled.div`
width: 15%;
height: 2em;
background-color: ${colors.white};
display: flex;
justify-content: center;
align-items: center;
border-radius: 5px;
.css-i4bv87-MuiSvgIcon-root{
  color: ${colors.brown};
}
`