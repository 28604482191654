import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useGetCompletedTaskQuery } from "../../../features/taskSlice";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../../Shared/colors";

const columns = [
  {
    field: "text",
    headerName: "Feedback Text",
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.text}>
          <div>{params.row.text}</div>
        </Tooltip>
      );
    },
  },
  {
    field: "date",
    headerName: "Feedback Date",
    width: 250,
    renderCell: (params) => {
      let date = params.row.date;
      return <div>{date.toDateString()}</div>;
    },
  },
];

export const FeedbackTable = ({ feedback }) => {
  const [pageSize, setPageSize] = useState(5);

  let rows = [];

  feedback?.forEach((feedback) => {
    rows.push({
      id: feedback._id,
      text: feedback.text,
      date: new Date(feedback?.date),
    });
  });

  console.log("this is feedback data", feedback, rows);
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection={false}
      disableSelectionOnClick
      experimentalFeatures={{ newEditingApi: true }}
      sx={{
        border: "none",
      }}
    />
  );
};
